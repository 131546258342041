import { Badge } from "components/ui/badge";
import { DatePickerWithRange } from "components/ui/date-range-picker";
import { Label } from "components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
} from "components/ui/select";
import { Switch } from "components/ui/switch";
import { useState } from "react";
import MetricsChart from "views/dashboard/_components/metrics-chart.jsx";
import MetricsChartVsGoal from "./metric-chart-vs-goal";

const metrics = [
	{
		name: "posts",
		bgColor: "rgba(190, 220, 255, 0.8)", // Light blue
		borderColor: "rgba(0, 64, 133, 0.8)",
	},
	{
		name: "reach",
		bgColor: "rgba(170, 200, 255, 0.8)", // Slightly darker blue
		borderColor: "rgba(0, 64, 133, 0.8)",
	},
	{
		name: "likes",
		bgColor: "rgba(150, 180, 255, 0.8)", // Medium blue
		borderColor: "rgba(0, 64, 133, 0.8)",
	},
	{
		name: "comments",
		bgColor: "rgba(130, 160, 255, 0.8)", // Darker blue
		borderColor: "rgba(0, 64, 133, 0.8)",
	},
];

const CollaboratorsMetricsCharts = ({
	organizationId,
	t,
	goalMetrics,
	weeklyProgressData,
	goal,
	userInitialGoalMetrics,
}) => {
	const [dateRange, setDateRange] = useState(() => {
		const today = new Date();
		const firstDayOfYear = new Date(today.getFullYear(), 0, 1);

		return { from: firstDayOfYear, to: today };
	});

	const [vsGoal, setVsGoal] = useState(false);
	const [timeInterval, setTimeInterval] = useState("weekly");

	return (
		<section>
			<div className="flex gap-4">
				<DatePickerWithRange
					from={dateRange.from}
					to={dateRange.to}
					onDateChange={({ from, to }) => setDateRange({ from, to })}
					disabled={vsGoal}
				/>
				<Select
					defaultValue={timeInterval}
					onValueChange={setTimeInterval}
					disabled={vsGoal}
				>
					<SelectTrigger className="max-w-[8rem]">
						{t(`dashboard.collaborator_metrics.${timeInterval}`)}
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="daily">
							{t("dashboard.collaborator_metrics.daily")}
						</SelectItem>
						<SelectItem value="weekly">
							{t("dashboard.collaborator_metrics.weekly")}
						</SelectItem>
						<SelectItem value="monthly">
							{t("dashboard.collaborator_metrics.monthly")}
						</SelectItem>
						<SelectItem value="yearly">
							{t("dashboard.collaborator_metrics.yearly")}
						</SelectItem>
					</SelectContent>
				</Select>
				{weeklyProgressData?.length && goalMetrics?.length ? (
					<Badge variant="outline" className="shadow-sm">
						<div className="flex items-center space-x-2 w-full">
							<Switch
								id="switch"
								checked={vsGoal}
								onCheckedChange={setVsGoal}
							/>
							<Label htmlFor="switch">
								{t("dashboard.collaborator_metrics.vs_goal")}
							</Label>
						</div>
					</Badge>
				) : null}
			</div>
			<div className="grid gap-4 sm:grid-cols-2 mt-8">
				{metrics.map((metric) => (
					<div key={metric.name} className="w-full">
						{vsGoal ? (
							<MetricsChartVsGoal
								organizationId={organizationId}
								t={t}
								metric={metric.name}
								backgroundColor={metric.bgColor}
								borderColor={metric.borderColor}
								weeklyProgressData={weeklyProgressData}
								goalMetrics={goalMetrics}
								goal={goal}
								userInitialGoalMetrics={userInitialGoalMetrics}
							/>
						) : (
							<MetricsChart
								organizationId={organizationId}
								t={t}
								dates={dateRange}
								metric={metric.name}
								timeInterval={timeInterval}
								showInputs={false}
								backgroundColor={metric.bgColor}
								borderColor={metric.borderColor}
							/>
						)}
					</div>
				))}
			</div>
		</section>
	);
};

export default CollaboratorsMetricsCharts;
