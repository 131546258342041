import BlogFromMultimedia from "./_components/tools/BlogFromMultimedia/page";
import CreateChatbotDemo from "./_components/tools/CreateChatbotDemo/page";
import CustomVoiceManagement from "./_components/tools/CustomVoiceManagement/page";
import ProfilePersonaCreation from "./_components/tools/ProfilePersonaCreation/page";
import PublishToLinkedin from "./_components/tools/PublishToLinkedin/page";
import { WordpressBlogFromLinkedinPost } from "./_components/tools/WordpressBlogFromLinkedinPost/page";
import type { Tool } from "./types";

export const tools: Tool[] = [
	{
		name: "Blog from LinkedIn Post",
		shortDescription: "Select a linkedin post and generate a blog post",
		longDescription:
			"Publish a blog post in a matter of seconds by selecting a linkedin post.",
		href: "/dashboard/content-creation/blog-from-linkedin-post",
		component: WordpressBlogFromLinkedinPost,
		released: false,
		internal: false,
	},
	{
		name: "Blog from Multimedia",
		shortDescription: "Select a multimedia and generate a blog post",
		longDescription:
			"Publish a blog post in a matter of seconds by selecting a multimedia.",
		href: "/dashboard/content-creation/blog-from-multimedia",
		component: BlogFromMultimedia,
		released: true,
		internal: false,
	},
	{
		name: "Publish to Linkedin",
		shortDescription: "Publish a post to Linkedin from Inbound Tools",
		longDescription:
			"Select an organization and publish a post to LinkedIn using their tokens.",
		href: "/dashboard/content-creation/publish-to-linkedin",
		component: PublishToLinkedin,
		released: true,
		internal: true,
	},
	{
		name: "Create LinkedIn Profile Persona (internal)",
		shortDescription: "Create a profile persona for a user",
		longDescription:
			"Create a profile persona based on user information, LinkedIn PDF, and target audience. Give access to the chatbot via this tool.",
		href: "/dashboard/profile-persona/creation",
		component: ProfilePersonaCreation,
		released: true,
		internal: true,
	},
	{
		name: "Create chatbot demo (internal)",
		shortDescription: "Crear una demo de chatbot",
		longDescription:
			"Crea una demo de chatbot con nombre, metadatos y publicaciones de LinkedIn de muestra.",
		href: "/dashboard/content-creation/create-chatbot-demo",
		component: CreateChatbotDemo,
		released: true,
		internal: true,
	},
	{
		name: "Custom Voice Management (internal)",
		shortDescription: "Manage all custom voices",
		longDescription: "View and edit custom voices for all users",
		href: "/dashboard/content-creation/custom-voice-management",
		component: CustomVoiceManagement,
		released: true,
		internal: true,
	},
];
