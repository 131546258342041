import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import { LoaderCircle } from "lucide-react";
import type React from "react";
import { useEffect, useState } from "react";
import { type FieldErrors, type SubmitHandler, useForm } from "react-hook-form";
import type { z } from "zod";

import { useToast } from "components/hooks/use-toast";
import { usePostHog } from "posthog-js/react";
import { Button } from "./button";

export interface MultiFormStep<T extends z.ZodType<any, any>, U extends any[]> {
	id: string;
	name: string;
	schema: T;
	component: React.ComponentType<{
		register: ReturnType<typeof useForm<z.infer<T>>>["register"];
		errors: FieldErrors<z.infer<T>>;
		values: z.infer<T>;
		previousValues: U;
		watch: ReturnType<typeof useForm<z.infer<T>>>["watch"];
		metadata: Record<string, unknown>[];
		control: ReturnType<typeof useForm<z.infer<T>>>["control"];
		setValue: ReturnType<typeof useForm<z.infer<T>>>["setValue"];
		t: (key: string) => string;
		captureEvent?: (
			eventName: string,
			properties?: Record<string, unknown>,
		) => void;
	}>;
	fn?: (
		data: z.infer<T>,
		allData: U,
		captureEvent?: (
			eventName: string,
			properties?: Record<string, unknown>,
		) => void,
	) => Promise<void | Record<string, unknown>>;
}

interface FormProps<T extends z.ZodType<any, any>[]> {
	name: string;
	steps: { [K in keyof T]: MultiFormStep<T[K], z.infer<T[number]>[]> };
	onSubmit: SubmitHandler<z.infer<T[number]>[]>;
	useLocalStorage?: boolean;
	onSuccessButton?: React.ReactNode;
	nextButton?: React.ReactNode;
	prevButton?: React.ReactNode;
	t: (key: string) => string;
	captureEvent?: (
		eventName: string,
		properties?: Record<string, unknown>,
	) => void;
}

export default function MultiStepForm<T extends z.ZodType<any, any>[]>({
	name,
	steps,
	onSubmit,
	useLocalStorage = false,
	nextButton,
	onSuccessButton,
	prevButton,
	t,
	captureEvent,
}: FormProps<T>) {
	const [currentStep, setCurrentStep] = useState(0);
	const [formData, setFormData] = useState<z.infer<T[number]>[]>([]);
	const [stepMetadata, setStepMetadata] = useState<Record<string, unknown>[]>(
		[],
	);
	const [isLoading, setIsLoading] = useState(false);
	const [highestStepReached, setHighestStepReached] = useState(-1);

	const currentSchema = steps[currentStep].schema;
	const form = useForm<z.infer<typeof currentSchema>>({
		resolver: zodResolver(currentSchema),
	});

	const posthog = usePostHog();
	const { toast } = useToast();

	useEffect(() => {
		if (highestStepReached === -1 && posthog) {
			setHighestStepReached(0);
			posthog.capture(`${name}_${steps[0].id}`, {
				step_name: steps[0].name,
			});
		}
	}, [highestStepReached, steps, posthog, name]);

	useEffect(() => {
		if (useLocalStorage) {
			const storedData = localStorage.getItem("multiStepFormData");
			if (storedData) {
				const parsedData = JSON.parse(storedData) as {
					formData: z.infer<T[number]>[];
					stepMetadata: Record<string, unknown>[];
					currentStep: number;
				};
				setFormData(parsedData.formData);
				setStepMetadata(parsedData.stepMetadata);
				setCurrentStep(parsedData.currentStep);
			}
		}
	}, [useLocalStorage]);

	useEffect(() => {
		if (useLocalStorage) {
			localStorage.setItem(
				"multiStepFormData",
				JSON.stringify({
					formData,
					stepMetadata,
					currentStep,
				}),
			);
		}
	}, [formData, stepMetadata, currentStep, useLocalStorage]);

	const saveStepData = async (data: z.infer<T[number]>) => {
		setIsLoading(true);
		const newFormData = [...formData];
		newFormData[currentStep] = data;
		setFormData(newFormData);

		const currentStepFn = steps[currentStep].fn;
		if (currentStepFn) {
			try {
				const metadata = await currentStepFn(
					data,
					newFormData.slice(0, currentStep) as any,
					captureEvent,
				);
				if (metadata && typeof metadata === "object") {
					const newStepMetadata = [...stepMetadata];
					newStepMetadata[currentStep] = metadata;
					setStepMetadata(newStepMetadata);
				}
			} catch (error) {
				console.error("Error in step function:", error);
				if (error instanceof Error) {
					if (error.message === "GENERATION_IN_PROGRESS") {
						toast({
							title: "Blog Generation in Progress",
							description:
								"There is another blog generation in progress. Please wait for it to finish before starting another one. Try again in a few minutes.",
							variant: "destructive",
						});
					} else {
						toast({
							title: "Error",
							description: error.message
								? `${error.message}. Please try again in one minute or contact support.`
								: "An error occurred while processing your request. Please try again in one minute or contact support.",
							variant: "destructive",
						});
					}
				}
				setIsLoading(false);
				throw error;
			}
		}
		setIsLoading(false);
	};

	const nextStep = async (data: z.infer<T[number]>) => {
		try {
			await saveStepData(data);
			if (currentStep < steps.length - 1) {
				const nextStepIndex = currentStep + 1;
				setCurrentStep(nextStepIndex);
				form.reset(formData[nextStepIndex]);

				if (nextStepIndex > highestStepReached) {
					setHighestStepReached(nextStepIndex);
					posthog.capture(`${name}_${steps[nextStepIndex].id}`, {
						step_name: steps[nextStepIndex].name,
						data: JSON.stringify(formData),
					});
				}
			} else {
				onSubmit(formData);
			}
		} catch (error) {
			console.error("Error in nextStep", { error, step: currentStep, data });
		}
	};

	const prevStep = () => {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1);
			form.reset(formData[currentStep - 1]);
		}
	};

	const CurrentStepComponent = steps[currentStep].component;

	return (
		<form onSubmit={form.handleSubmit(nextStep)}>
			<motion.div
				key={currentStep}
				initial={{ opacity: 0, x: 50 }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 0, x: -50 }}
			>
				<CurrentStepComponent
					register={form.register}
					errors={form.formState.errors}
					values={form.watch()}
					previousValues={formData.slice(0, currentStep) as any}
					metadata={stepMetadata}
					control={form.control}
					watch={form.watch}
					setValue={form.setValue}
					t={t}
					captureEvent={captureEvent}
				/>
			</motion.div>
			<div className="flex justify-between mt-4">
				{prevButton || (
					<Button
						type="button"
						onClick={prevStep}
						disabled={currentStep === 0 || isLoading}
					>
						Previous
					</Button>
				)}
				{isLoading ? (
					<Button type="button" disabled variant={"outline"}>
						<LoaderCircle className="animate-spin" />
					</Button>
				) : currentStep === steps.length - 1 ? (
					onSuccessButton || <Button type="submit">Finish</Button>
				) : (
					nextButton || <Button type="submit">Next</Button>
				)}
			</div>
		</form>
	);
}
