import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

declare global {
	interface Window {
		chrome: {
			runtime: {
				sendMessage: <T>(
					extensionId: string,
					message: { type: string },
					callback: (response: T) => void,
				) => void;
			};
		};
	}
}

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const interactWithExtension = <T>(
	messageType: string,
	callback: (response: T) => void,
) => {
	if (window.chrome?.runtime) {
		window.chrome.runtime.sendMessage(
			import.meta.env.VITE_EXTENSION_ID,
			{ type: messageType },
			callback,
		);
	} else {
		throw new Error("Chrome runtime not available");
	}
};

export function formatBytes(
	bytes: number,
	opts: {
		decimals?: number;
		sizeType?: "accurate" | "normal";
	} = {},
) {
	const { decimals = 0, sizeType = "normal" } = opts;

	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	const accurateSizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"];
	if (bytes === 0) return "0 Byte";
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	return `${(bytes / 1024 ** i).toFixed(decimals)} ${
		sizeType === "accurate" ? accurateSizes[i] ?? "Bytest" : sizes[i] ?? "Bytes"
	}`;
}

export const linkedinOAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${import.meta.env.VITE_LINKEDIN_CLIENT_ID}&redirect_uri=${import.meta.env.VITE_LINKEDIN_REDIRECT_URI}&scope=openid%20profile%20w_member_social`;

export function convertHTMLToPlainText(html: string) {
	const tempDivElement = document.createElement("div");

	tempDivElement.innerHTML = html;

	return tempDivElement.textContent || tempDivElement.innerText || "";
}

/**
 * Converts an HTML string to Gutenberg blocks serialized format, handling styles and a wide range of HTML elements.
 *
 * @param html - The HTML string to convert.
 * @returns A string of serialized Gutenberg blocks.
 */
export function htmlToGutenbergBlocks(html: string): string {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, "text/html");
	const blocks: string[] = [];

	function traverse(node: HTMLElement): string {
		const block = mapTagToBlock(node);
		if (!block) {
			return node.outerHTML;
		}

		const attrs = getAttributes(node, block);
		const attrString =
			Object.keys(attrs).length > 0 ? ` ${JSON.stringify(attrs)}` : "";

		let content = "";
		if (block.name === "list") {
			content = Array.from(node.children)
				.map((child) => {
					if (child.tagName.toLowerCase() === "li") {
						return `<!-- wp:list-item -->\n<li>${child.innerHTML}</li>\n<!-- /wp:list-item -->`;
					}
					return traverse(child as HTMLElement);
				})
				.join("\n");
		} else if (block.name === "image") {
			const imageUrl = node.getAttribute("src") || "";
			const alt = node.getAttribute("alt") || "";
			const imageAttrs = {
				url: imageUrl,
				alt: alt,
				id: generateUniqueId(),
			};
			const imageAttrString = JSON.stringify(imageAttrs);
			return `<!-- wp:image ${imageAttrString} -->\n<figure class="wp-block-image"><img src="${imageUrl}" alt="${alt}"/></figure>\n<!-- /wp:image -->`;
		} else if (block.name === "heading") {
			content = node.textContent || "";
			return `<!-- wp:heading${attrString} -->\n<h${attrs.level}>${content}</h${attrs.level}>\n<!-- /wp:heading -->`;
		} else if (block.name === "table") {
			content = generateTableContent(node);
			return `<!-- wp:table${attrString} -->\n${content}\n<!-- /wp:table -->`;
		} else {
			content = Array.from(node.childNodes)
				.map((child) => {
					if (child.nodeType === Node.ELEMENT_NODE) {
						return traverse(child as HTMLElement);
					}
					if (child.nodeType === Node.TEXT_NODE) {
						return child.textContent?.trim() || "";
					}
					return "";
				})
				.join("");
		}

		return `<!-- wp:${block.name}${attrString} -->\n${node.outerHTML}\n<!-- /wp:${block.name} -->`;
	}

	for (const child of doc.body.childNodes) {
		if (child.nodeType === Node.ELEMENT_NODE) {
			blocks.push(traverse(child as HTMLElement));
		} else if (child.nodeType === Node.TEXT_NODE) {
			const text = child.textContent?.trim();
			if (text) {
				blocks.push(
					`<!-- wp:paragraph -->\n<p>${text}</p>\n<!-- /wp:paragraph -->`,
				);
			}
		}
	}

	return blocks.join("\n\n");
}

/**
 * Maps an HTMLElement to a Gutenberg block configuration.
 *
 * @param element - The HTMLElement to map.
 * @returns An object containing the block name and default attributes, or null if no mapping exists.
 */
function mapTagToBlock(
	element: HTMLElement,
): { name: string; defaultAttributes?: Record<string, unknown> } | null {
	const tag = element.tagName.toLowerCase();
	const mapping: Record<
		string,
		{ name: string; defaultAttributes?: Record<string, unknown> }
	> = {
		p: { name: "paragraph" },
		h1: { name: "heading", defaultAttributes: { level: 1 } },
		h2: { name: "heading", defaultAttributes: { level: 2 } },
		h3: { name: "heading", defaultAttributes: { level: 3 } },
		h4: { name: "heading", defaultAttributes: { level: 4 } },
		h5: { name: "heading", defaultAttributes: { level: 5 } },
		h6: { name: "heading", defaultAttributes: { level: 6 } },
		blockquote: { name: "quote" },
		pre: { name: "preformatted" },
		code: { name: "code" },

		// List blocks
		ul: { name: "list", defaultAttributes: { ordered: false } },
		ol: { name: "list", defaultAttributes: { ordered: true } },
		li: { name: "list-item" },

		// Media blocks
		img: { name: "image" },
		figure: { name: "image" }, // Assuming figures contain images
		video: { name: "video" },
		audio: { name: "audio" },

		// Embed blocks (simplified, as actual embeds depend on the source)
		iframe: { name: "embed" },

		// Table blocks
		table: { name: "table" },
		thead: { name: "table" },
		tbody: { name: "table" },
		tr: { name: "table" },
		th: { name: "table" },
		td: { name: "table" },

		// Other common blocks
		hr: { name: "separator" },
		form: { name: "form" },
		button: { name: "button" },

		// Fallback for divs and other container elements
		div: { name: "group" },
		section: { name: "group" },
		article: { name: "group" },
		aside: { name: "group" },

		// Inline elements
		a: { name: "paragraph" }, // Links within paragraphs
		strong: { name: "paragraph" },
		em: { name: "paragraph" },
		span: { name: "paragraph" },
		br: { name: "paragraph" },
	};

	return mapping[tag] || null;
}

/**
 * Extracts and formats attributes from an HTMLElement for Gutenberg block.
 *
 * @param element - The HTMLElement to extract attributes from.
 * @param block - The mapped block information.
 * @returns An object of formatted attributes.
 */
function getAttributes(
	element: HTMLElement,
	block: { name: string; defaultAttributes?: Record<string, unknown> },
): Record<string, unknown> {
	const attrs: Record<string, string | number | boolean | unknown> = {
		...block.defaultAttributes,
	};

	for (const attr of element.attributes) {
		const { name, value } = attr;
		switch (name) {
			case "class":
				attrs.className = value;
				break;
			case "id":
				attrs.anchor = value;
				break;
			case "style":
				attrs.style = parseStyle(value);
				break;
			case "src":
				if (["image", "video", "audio", "iframe"].includes(block.name)) {
					attrs.url = value;
				}
				break;
			case "alt":
				if (block.name === "image") {
					attrs.alt = value;
				}
				break;
			case "href":
				if (element.tagName.toLowerCase() === "a") {
					attrs.url = value;
				}
				break;
			case "width":
			case "height":
				if (["image", "video", "iframe"].includes(block.name)) {
					attrs[name] = Number.parseInt(value, 10);
				}
				break;
			case "controls":
			case "autoplay":
			case "loop":
			case "muted":
				if (block.name === "video") {
					attrs[name] = true;
				}
				break;
		}
	}

	// Special handling for specific blocks
	switch (block.name) {
		case "button":
			attrs.text = element.textContent || "";
			break;
		case "embed":
			attrs.url = element.getAttribute("src") || "";
			attrs.type = "rich"; // Default embed type
			break;
	}

	return attrs;
}

// Helper function to generate a unique ID
function generateUniqueId(): string {
	return `image_${Math.random().toString(36).slice(2, 11)}`;
}

// Helper function to parse inline styles
function parseStyle(styleString: string): Record<string, string> {
	const styleObj: Record<string, string> = {};
	for (const style of styleString.split(";")) {
		const [key, value] = style.split(":").map((s) => s.trim());
		if (key && value) {
			const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
			styleObj[camelCaseKey] = value;
		}
	}
	return styleObj;
}

function generateTableContent(tableNode: HTMLElement): string {
	const thead = tableNode.querySelector("thead");
	const tbody = tableNode.querySelector("tbody");
	const tfoot = tableNode.querySelector("tfoot");

	console.log("asdas", thead, tbody, tfoot);

	let content = '<table class="wp-block-table">';

	if (thead) {
		content += `<thead>${generateTableSection(thead)}</thead>`;
	}

	if (tbody) {
		content += `<tbody>${generateTableSection(tbody)}</tbody>`;
	}

	if (tfoot) {
		content += `<tfoot>${generateTableSection(tfoot)}</tfoot>`;
	}

	content += "</table>";

	return content;
}

function generateTableSection(sectionNode: HTMLElement): string {
	return Array.from(sectionNode.children)
		.map((row) => {
			const cells = Array.from(row.children)
				.map(
					(cell) =>
						`<${cell.tagName.toLowerCase()}>${cell.innerHTML}</${cell.tagName.toLowerCase()}>`,
				)
				.join("");
			return `<tr>${cells}</tr>`;
		})
		.join("");
}
