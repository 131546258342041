import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { useState } from "react";
import SyncStatusBadge from "./sync-status-badge";

import { useNavigate } from "react-router-dom";
import UserAnalytics from "./user-analytics";

const SortableHeaderButton = ({ column, label }) => {
	return (
		<Button
			className="px-0"
			variant="ghost"
			onClick={() => {
				column.toggleSorting();
			}}
		>
			{label}
			{column.getIsSorted() === "desc" ? (
				<ChevronDownIcon className="ml-2 w-4 h-4" />
			) : (
				column.getIsSorted() === "asc" && (
					<ChevronUpIcon className="ml-2 w-4 h-4" />
				)
			)}
		</Button>
	);
};

const TotalRow = ({ metrics }) => {
	if (!metrics) return null;

	const totals = {
		posts: metrics.reduce((sum, metric) => sum + metric.posts, 0),
		previousPosts: metrics.reduce(
			(sum, metric) => sum + (metric.previousPosts || 0),
			0,
		),
		blogs: metrics.reduce((sum, metric) => sum + (metric.blogs || 0), 0),
		previousBlogs: metrics.reduce(
			(sum, metric) => sum + (metric.previousBlogs || 0),
			0,
		),
		reach: metrics.reduce((sum, metric) => sum + metric.reach, 0),
		previousReach: metrics.reduce(
			(sum, metric) => sum + (metric.previousReach || 0),
			0,
		),
		interactions: metrics.reduce((sum, metric) => sum + metric.interactions, 0),
		previousInteractions: metrics.reduce(
			(sum, metric) => sum + (metric.previousInteractions || 0),
			0,
		),
		comments: metrics.reduce((sum, metric) => sum + metric.comments, 0),
		previousComments: metrics.reduce(
			(sum, metric) => sum + (metric.previousComments || 0),
			0,
		),
		followers: metrics.reduce((sum, metric) => sum + metric.followers, 0),
		previousFollowers: metrics.reduce(
			(sum, metric) => sum + (metric.previousFollowers || 0),
			0,
		),
	};

	totals.postsChange = totals.previousPosts
		? ((totals.posts - totals.previousPosts) / totals.previousPosts) * 100
		: 0;
	totals.reachChange = totals.previousReach
		? ((totals.reach - totals.previousReach) / totals.previousReach) * 100
		: 0;
	totals.commentsChange = totals.previousComments
		? ((totals.comments - totals.previousComments) / totals.previousComments) *
			100
		: 0;
	totals.interactionsChange = totals.previousInteractions
		? ((totals.interactions - totals.previousInteractions) /
				totals.previousInteractions) *
			100
		: 0;
	totals.blogsChange = totals.previousBlogs
		? ((totals.blogs - totals.previousBlogs) / totals.previousBlogs) * 100
		: 0;
	const currentEngagement = totals.reach
		? (totals.interactions / totals.reach) * 100
		: 0;
	const previousEngagement = totals.previousReach
		? (totals.previousInteractions / totals.previousReach) * 100
		: 0;
	const engagementChange = previousEngagement
		? ((currentEngagement - previousEngagement) / previousEngagement) * 100
		: 0;

	return (
		<TableRow>
			<TableCell className="font-bold text-base">Total</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.posts}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.postsChange > 0
							? "text-green-500"
							: totals.postsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.postsChange > 0 ? "+" : ""}
					{Math.round(totals.postsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.blogs}
			</TableCell>
			<TableCell className="text-center border-l text-base font-bold">
				<span
					className={`font-bold ${
						totals.blogsChange > 0
							? "text-green-500"
							: totals.blogsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.blogsChange > 0 ? "+" : ""}
					{Math.round(totals.blogsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.reach}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.reachChange > 0
							? "text-green-500"
							: totals.reachChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.reachChange > 0 ? "+" : ""}
					{Math.round(totals.reachChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.interactions}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.interactionsChange > 0
							? "text-green-500"
							: totals.interactionsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.interactionsChange > 0 ? "+" : ""}
					{Math.round(totals.interactionsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.comments}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						totals.commentsChange > 0
							? "text-green-500"
							: totals.commentsChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{totals.commentsChange > 0 ? "+" : ""}
					{Math.round(totals.commentsChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.followers}
			</TableCell>
			<TableCell className="text-center border-l text-base font-bold">
				+{0}
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{currentEngagement.toFixed(2)}%
			</TableCell>
			<TableCell className="text-center border-l text-base">
				<span
					className={`font-bold ${
						engagementChange > 0
							? "text-green-500"
							: engagementChange < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					{engagementChange > 0 ? "+" : ""}
					{Math.round(engagementChange)}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l" />{" "}
			{/* Empty cell for Status column */}
		</TableRow>
	);
};

const getColumns = (t, usersSyncStatus) => [
	{
		accessorKey: "user_name",
		title: t("dashboard.collaborator_metrics.user_name"),
		header: ({ column }) => (
			<SortableHeaderButton
				column={column}
				label={t("dashboard.collaborator_metrics.user_name")}
			/>
		),
		cell: (info) => {
			return <span className="text-base">{info.getValue()}</span>;
		},
	},
	{
		header: t("dashboard.collaborator_metrics.posts"),
		columns: [
			{
				accessorKey: "posts",
				title: t("dashboard.collaborator_metrics.posts"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				accessorKey: "postsChange",
				title: t("dashboard.collaborator_metrics.postsChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.blogs"),
		columns: [
			{
				accessorKey: "blogs",
				title: t("dashboard.collaborator_metrics.blogs"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				accessorKey: "blogsChange",
				title: t("dashboard.collaborator_metrics.blogsChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{change}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.reach"),
		columns: [
			{
				accessorKey: "reach",
				title: t("dashboard.collaborator_metrics.reach"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				accessorKey: "reachChange",
				title: t("dashboard.collaborator_metrics.reachChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.interactions"),
		columns: [
			{
				accessorKey: "interactions",
				title: t("dashboard.collaborator_metrics.interactions"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				accessorKey: "interactionsChange",
				title: t("dashboard.collaborator_metrics.interactionsChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.comments"),
		columns: [
			{
				accessorKey: "comments",
				title: t("dashboard.collaborator_metrics.comments"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				accessorKey: "commentsChange",
				title: t("dashboard.collaborator_metrics.commentsChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.followers"),
		columns: [
			{
				accessorKey: "followers",
				title: t("dashboard.collaborator_metrics.followers"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
			},
			{
				accessorKey: "followersChange",
				title: t("dashboard.collaborator_metrics.followersChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}+{change}
						</span>
					);
				},
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.engagement"),
		columns: [
			{
				accessorKey: "engagement",
				title: t("dashboard.collaborator_metrics.engagement"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => {
					const engagement = info.getValue();
					return <span className="text-base">{engagement.toFixed(2)}%</span>;
				},
				sortingFn: (rowA, rowB) => {
					const engagementA = rowA.original.engagement || 0;
					const engagementB = rowB.original.engagement || 0;
					return engagementA - engagementB;
				},
			},
			{
				accessorKey: "engagementChange",
				title: t("dashboard.collaborator_metrics.engagementChange"),
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => {
					const change = info.getValue();
					const colorClass =
						change > 0
							? "text-green-500"
							: change < 0
								? "text-red-500"
								: "text-black";
					return (
						<span className={`font-bold text-base ${colorClass}`}>
							{change > 0 ? "+" : ""}
							{Math.round(change)}%
						</span>
					);
				},
			},
		],
	},
	{
		accessorKey: "user_id",
		title: t("dashboard.collaborator_metrics.status"),
		header: () => <span>{t("dashboard.collaborator_metrics.status")}</span>,
		cell: (info) => {
			const user = usersSyncStatus.find((u) => u.user_id === info.getValue());
			return user ? (
				<SyncStatusBadge
					syncStatus={user.sync_status}
					lastSyncDate={user.last_sync_date}
				/>
			) : (
				<span>-</span>
			);
		},
		enableSorting: false,
	},
];

const CollaboratorMetricsTable = ({ metrics, t, usersSyncStatus }) => {
	const navigate = useNavigate();
	const [sorting, setSorting] = useState([{ id: "posts", desc: true }]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const table = useReactTable({
		data: metrics,
		columns: getColumns(t, usersSyncStatus),
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
		},
	});

	const handleRowClick = (userId) => {
		navigate(`/stats/${userId}`);
	};

	return (
		<div className="w-full">
			<div className="flex items-center py-4">
				<Input
					placeholder={t("dashboard.collaborator_metrics.filter_user_names")}
					value={table.getColumn("user_name")?.getFilterValue() ?? ""}
					onChange={(event) =>
						table.getColumn("user_name")?.setFilterValue(event.target.value)
					}
					className="max-w-sm"
				/>
			</div>
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header, headerIndex) => {
								const isFirstHeader = headerIndex === 0;
								const isLastHeader =
									headerIndex === headerGroup.headers.length - 1;
								return (
									<TableHead
										key={header.id}
										colSpan={header.colSpan}
										className={`text-center ${!isFirstHeader ? "border-l" : ""} ${!isLastHeader ? "border-r" : ""}`}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					<TotalRow metrics={metrics} />
					{table.getRowModel().rows.map((row, rowIndex) => (
						<TableRow
							key={row.id}
							className={`hover:cursor-pointer hover:bg-muted-foreground/10 ${
								rowIndex % 2 === 0 ? "bg-gray-100" : "bg-white"
							}`}
							onClick={() => handleRowClick(row.original.user_id)}
						>
							{row.getVisibleCells().map((cell, cellIndex) => {
								const isFirstCell = cellIndex === 0;
								const isLastCell =
									cellIndex === row.getVisibleCells().length - 1;
								return (
									<TableCell
										key={cell.id}
										className={`${cell.column.id !== "user_name" ? "text-center" : ""} ${!isFirstCell ? "border-l" : ""} ${!isLastCell ? "border-r" : ""}`}
										style={
											cell.column.columnDef.cellProps
												? cell.column.columnDef.cellProps.style(cell)
												: {}
										}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								);
							})}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	);
};

export default CollaboratorMetricsTable;
