/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { AuthContext } from "components/lib";
import { useNavigate } from "components/lib";
import { Button } from "components/lib";
import { DatePickerWithRange } from "components/ui/date-range-picker";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { useCallback, useContext, useEffect, useState } from "react";
import type { DateRange } from "react-day-picker";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { z } from "zod";

import { useQuery } from "@tanstack/react-query";
import { useToast } from "components/hooks/use-toast";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import type {
	AuthOrganization,
	GrowthMetrics,
	PredictedMetricValues,
	UserPredictedMetricData,
} from "../types";
import {
	accumulateMetrics,
	calculateWeeklyGrowth,
	calculateWeeklyGrowthFromFixed,
	filterMetricsBySelectedUsers,
} from "../utils";
import { UserSelectionDialog } from "./user-selection-dialog";

const goalSchema = z.object({
	title: z.string().min(1, "Title is required"),
	description: z.string().min(1, "Description is required"),
	start_date: z.string().min(1, "Start date is required"),
	end_date: z.string().min(1, "End date is required"),
	goals: z.array(
		z.object({
			metric_type: z.string().min(1, "Metric Type is required"),
			value: z.number().min(1, "Value must be at least 1").optional(),
		}),
	),
});

const possibleMetrics = ["posts", "reach", "likes", "comments"];
const possibleMultipliers = [1, 1.1, 1.25, 1.5, 2, 2.5, 3];

export type OrganizationUser = {
	id: string;
	name: string;
};

export type GoalUser = {
	id: string;
	name: string;
	posts: number;
};

export const GoalCreation = () => {
	// biome-ignore lint/suspicious/noExplicitAny: <auth hook>
	const auth: any = useContext(AuthContext);
	const organization_id = auth.user.organization_id;
	const organizationName = auth.user?.organizations?.find(
		(org: AuthOrganization) => org.id === auth.user?.organization_id,
	)?.name;
	const [selectedUsers, setSelectedUsers] = useState<GoalUser[]>([]);

	const { toast } = useToast();

	const { data: metrics, isLoading: metricsLoading } =
		useQuery<UserPredictedMetricData>({
			queryKey: ["weeklyMetrics", organization_id],
			queryFn: async () => {
				const response = await axios.get(
					`/api/analytics/${organization_id}/weekly-metrics`,
				);
				if (response.status !== 200) {
					toast({
						title: "Error",
						description: "Failed to fetch metrics",
						variant: "destructive",
					});
					return [];
				}
				return response.data.data;
			},
			enabled: !!organization_id,
		});

	const { data: organizationUsers, isLoading: organizationUsersLoading } =
		useQuery<GoalUser[]>({
			queryKey: ["organizationUsers", organization_id],
			queryFn: async () => {
				const response = await axios.get("/api/organization/users");
				const users: GoalUser[] = response.data.data.users
					.filter(
						(user: OrganizationUser) =>
							// remove SoporteIBT
							user.id !== "a8437da7-b295-4d2f-8477-2b4c745da212",
					)
					.map((user: OrganizationUser) => ({
						id: user.id,
						name: user.name,
						posts: 1,
					}));

				setSelectedUsers(users);
				return users;
			},
			enabled: !!organization_id,
		});

	const [dateRange, setDateRange] = useState<DateRange>(() => {
		const today = new Date();
		const threeMonthsFromToday = new Date(today);
		threeMonthsFromToday.setMonth(today.getMonth() + 3);

		return { from: today, to: threeMonthsFromToday };
	});

	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const [accumulatedMetrics, setAccumulatedMetrics] =
		useState<PredictedMetricValues>();
	const [weeklyGrowthRate, setWeeklyGrowthRate] = useState<GrowthMetrics>();

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(goalSchema),
		defaultValues: {
			title: "",
			description: "",
			start_date: dateRange.from?.toISOString() || "",
			end_date: dateRange.to?.toISOString() || "",
			goals: possibleMetrics.map((metric) => ({
				metric_type: metric,
				value: metric === "posts" ? 1 : 2,
			})),
		},
	});

	const { fields, replace } = useFieldArray({
		control,
		name: "goals",
	});

	const watchGoals = useWatch({
		control,
		name: "goals",
	});

	const [goalType, setGoalType] = useState<"multiplier" | "fixed">(
		"multiplier",
	);

	useEffect(() => {
		if (metrics && selectedUsers.length > 0) {
			const filteredMetrics = filterMetricsBySelectedUsers(
				metrics,
				selectedUsers,
			);
			const accumulated = accumulateMetrics(filteredMetrics);
			setAccumulatedMetrics(accumulated);
		}
	}, [metrics, selectedUsers]);

	useEffect(() => {
		if (accumulatedMetrics) {
			const growthMultipliers: GrowthMetrics = {
				likes: null,
				comments: null,
				reach: null,
				shares: null,
			};
			for (const goal of watchGoals) {
				if (!goal.metric_type || goal.metric_type === "posts") {
					continue;
				}
				growthMultipliers[goal.metric_type as keyof GrowthMetrics] = goal.value;
			}
			if (Object.keys(growthMultipliers).length === 0) {
				return;
			}
			const growth =
				goalType === "multiplier"
					? calculateWeeklyGrowth(
							accumulatedMetrics,
							growthMultipliers,
							dateRange,
						)
					: calculateWeeklyGrowthFromFixed(
							accumulatedMetrics,
							growthMultipliers,
							dateRange,
						);
			setWeeklyGrowthRate(growth);
		}
	}, [watchGoals, accumulatedMetrics, dateRange, goalType]);

	const updateGoalValues = useCallback(
		(newGoalType: "multiplier" | "fixed") => {
			const updatedGoals = fields.map((field) => {
				if (field.metric_type !== "posts") {
					let defaultValue: number;
					if (newGoalType === "fixed" && accumulatedMetrics) {
						defaultValue =
							Math.floor(
								accumulatedMetrics[
									field.metric_type as keyof PredictedMetricValues
								],
							) || 0;
					} else {
						defaultValue = newGoalType === "fixed" ? 5 : 2;
					}
					return { ...field, value: defaultValue };
				}
				return field;
			});
			replace(updatedGoals);
		},
		[fields, accumulatedMetrics, replace],
	);

	// Use this function when the tab changes
	const handleGoalTypeChange = (value: "multiplier" | "fixed") => {
		setGoalType(value);
		updateGoalValues(value);
	};

	const handlePostsChange = (value: number) => {
		setSelectedUsers((prevUsers) =>
			prevUsers.map((user) => ({
				...user,
				posts: value,
			})),
		);
	};

	const onSubmit = async (data: any) => {
		setLoading(true);
		try {
			if (!metrics) {
				toast({
					title: "Error",
					description: "Failed to fetch metrics",
					variant: "destructive",
				});
				return;
			}

			if (goalType === "fixed") {
				const invalidMetrics = data.goals.reduce((acc: string[], goal: any) => {
					if (goal.metric_type === "posts") return acc;
					const accumulatedValue =
						accumulatedMetrics?.[
							goal.metric_type as keyof PredictedMetricValues
						] || 0;
					if (goal.value <= accumulatedValue) {
						acc.push(
							`${goal.metric_type.charAt(0).toUpperCase() + goal.metric_type.slice(1)}`,
						);
					}
					return acc;
				}, []);

				if (invalidMetrics.length > 0) {
					const metricsString = invalidMetrics.join(", ");
					toast({
						title: "Invalid Goal Values",
						description: `The following metrics must be higher than their accumulated values: ${metricsString}`,
						variant: "destructive",
					});
					return;
				}
			}

			const filteredMetrics = Object.entries(metrics)
				.filter(([userId]) => selectedUsers.some((user) => user.id === userId))
				.reduce(
					(acc, [userId, metricValues]) => {
						const user = selectedUsers.find((user) => user.id === userId);
						acc[userId] = {
							...metricValues,
							posts: user?.posts || 1,
						};
						return acc;
					},
					{} as Record<
						string,
						UserPredictedMetricData[string] & { posts: number }
					>,
				);

			if (Object.keys(filteredMetrics).length === 0) {
				toast({
					title: "Error",
					description: "No users selected",
					variant: "destructive",
				});
				return;
			}

			const totalPosts = selectedUsers.reduce(
				(sum, user) => sum + (user.posts || 0),
				0,
			);

			const updatedAccumulatedMetrics = {
				...accumulatedMetrics,
				posts: totalPosts,
			};

			const payload = {
				organization_id,
				...data,
				accumulated_metrics: updatedAccumulatedMetrics,
				metrics: filteredMetrics,
				weekly_growth_rate: weeklyGrowthRate,
			};

			await axios.post("/api/goal/create", payload);
			toast({
				title: "Success",
				description: "Goal created successfully",
			});
			navigate("/organization-goals");
		} catch (error) {
			if (error instanceof Error) {
				toast({
					title: "Error",
					description: `Failed to create goal: ${error.message}`,
					variant: "destructive",
				});
			} else {
				toast({
					title: "Error",
					description: "Failed to create goal",
					variant: "destructive",
				});
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<main className="flex justify-center min-h-screen bg-background">
			<form className="max-w-md w-full space-y-4 p-4 sm:p-6 md:p-8">
				<div>
					<h1 className="font-bold m-0">Create Goal</h1>
					<p className="text-muted-foreground">
						Set your weekly goals for {/* @ts-ignore */}
						{<b>{organizationName}</b> || "your organization"}
					</p>
				</div>
				<div className="space-y-4">
					<div className="border-b border-gray-300 pb-4 mb-4 grid gap-4">
						<div className="grid gap-2">
							<Label htmlFor="title">Title</Label>
							<Controller
								name="title"
								control={control}
								render={({ field }) => (
									<Input
										id="title"
										placeholder="Enter title"
										{...field}
										className="bg-white"
									/>
								)}
							/>
							{errors.title && (
								<span className="text-red-500">{errors.title.message}</span>
							)}
						</div>
						<div className="grid gap-2">
							<Label htmlFor="description">Description</Label>
							<Controller
								name="description"
								control={control}
								render={({ field }) => (
									<Input
										id="description"
										placeholder="Enter description"
										{...field}
										className="bg-white"
									/>
								)}
							/>
							{errors.description && (
								<span className="text-red-500">
									{errors.description.message}
								</span>
							)}
						</div>
						<div className="grid gap-2">
							<Label htmlFor="timeframe">Time Frame</Label>
							<DatePickerWithRange
								from={dateRange.from}
								to={dateRange.to}
								onDateChange={(newDateRange) => {
									setDateRange(newDateRange as DateRange);
									setValue(
										"start_date",
										newDateRange?.from?.toISOString() || "",
									);
									setValue("end_date", newDateRange?.to?.toISOString() || "");
								}}
							/>
							{errors.start_date && (
								<span className="text-red-500">
									{errors.start_date.message}
								</span>
							)}
							{errors.end_date && (
								<span className="text-red-500">{errors.end_date.message}</span>
							)}
						</div>
					</div>
				</div>
				<div className="space-y-4">
					{metrics && organizationUsers && (
						<div className="">
							<Label className="text-lg font-bold">Users</Label>
							<p className="text-sm text-gray-500 mb-2">
								Select the users to include in this goal
							</p>
							<UserSelectionDialog
								metrics={metrics}
								organizationUsers={organizationUsers}
								selectedUsers={selectedUsers}
								onSelectionChange={setSelectedUsers}
							/>
						</div>
					)}
					<div className="border-b border-gray-300 pb-4">
						<Label className="text-lg font-bold">Posts</Label>
						<p className="text-sm text-gray-500 mb-2">
							Set the number of posts for this goal period
						</p>
						{fields.map(
							(goal, index) =>
								goal.metric_type === "posts" && (
									<div key={goal.id}>
										<Controller
											name={`goals.${index}.value`}
											control={control}
											render={({ field }) => (
												<Input
													type="number"
													className="bg-white"
													value={field.value}
													onChange={(e) => {
														const newValue = e.target.valueAsNumber;
														field.onChange(newValue);
														handlePostsChange(newValue);
													}}
												/>
											)}
										/>
										{errors.goals?.[index]?.value && (
											<span className="text-red-500">
												{errors.goals[index]?.value?.message}
											</span>
										)}
									</div>
								),
						)}
					</div>

					<Tabs
						value={goalType}
						onValueChange={(value: string) => {
							const goalType = value as "multiplier" | "fixed";
							handleGoalTypeChange(goalType);
						}}
					>
						<TabsList className="grid w-full grid-cols-2">
							<TabsTrigger value="multiplier">Growth Multiplier</TabsTrigger>
							<TabsTrigger value="fixed">Fixed Target</TabsTrigger>
						</TabsList>
						<TabsContent value="multiplier">
							<div>
								<Label className="text-lg font-bold">Engagement Metrics</Label>
								<p className="text-sm text-gray-500 mb-2">
									Set growth multipliers for each metric
								</p>
								{fields.map(
									(goal, index) =>
										goal.metric_type !== "posts" && (
											<div
												key={goal.id}
												className="border-b border-gray-300 pb-4 mt-4"
											>
												<div className="grid">
													<Label className="text-md font-semibold">
														{goal.metric_type.charAt(0).toUpperCase() +
															goal.metric_type.slice(1)}
													</Label>
													<Controller
														name={`goals.${index}.value`}
														control={control}
														render={({ field }) => (
															<Select
																value={String(field.value)}
																onValueChange={(value) => {
																	field.onChange(Number(value));
																}}
															>
																<SelectTrigger className="bg-white">
																	<SelectValue>
																		{field.value
																			? `${field.value}x`
																			: "Select multiplier"}
																	</SelectValue>
																</SelectTrigger>
																<SelectContent>
																	{possibleMultipliers.map((multiplier) => (
																		<SelectItem
																			key={multiplier}
																			value={String(multiplier)}
																		>
																			{multiplier}x
																		</SelectItem>
																	))}
																</SelectContent>
															</Select>
														)}
													/>
													{errors.goals?.[index]?.value && (
														<span className="text-red-500">
															{errors.goals[index]?.value?.message}
														</span>
													)}
												</div>
												{accumulatedMetrics && (
													<div className="mt-2">
														{!metricsLoading && (
															<div className="text-gray-500">
																<p>
																	Organization weekly {goal.metric_type} in the
																	last 3 months:{" "}
																	<b>
																		{Math.floor(
																			accumulatedMetrics[
																				goal.metric_type as keyof PredictedMetricValues
																			],
																		) || 0}
																	</b>
																</p>
															</div>
														)}
														{weeklyGrowthRate?.[
															goal.metric_type as keyof GrowthMetrics
														] && (
															<div className="text-gray-500">
																<p>
																	Weekly growth needed for {goal.metric_type}:{" "}
																	<b>
																		{weeklyGrowthRate[
																			goal.metric_type as keyof GrowthMetrics
																		]?.toFixed(2) || 0}
																	</b>
																</p>
															</div>
														)}
													</div>
												)}
											</div>
										),
								)}
							</div>
						</TabsContent>
						<TabsContent value="fixed">
							<div>
								<Label className="text-lg font-bold">Engagement Metrics</Label>
								<p className="text-sm text-gray-500 mb-2">
									Set target values for each metric
								</p>
								{fields.map(
									(goal, index) =>
										goal.metric_type !== "posts" && (
											<div
												key={goal.id}
												className="border-b border-gray-300 pb-4 mt-4"
											>
												<div className="grid">
													<Label className="text-md font-semibold">
														{goal.metric_type.charAt(0).toUpperCase() +
															goal.metric_type.slice(1)}
													</Label>
													<Controller
														name={`goals.${index}.value`}
														control={control}
														render={({ field }) => (
															<Input
																type="number"
																className="bg-white"
																value={field.value === 0 ? "" : field.value}
																onChange={(e) => {
																	const value = e.target.valueAsNumber;
																	field.onChange(
																		Number.isNaN(value) ? 0 : value,
																	);
																}}
															/>
														)}
													/>
													{errors.goals?.[index]?.value && (
														<span className="text-red-500">
															{errors.goals[index]?.value?.message}
														</span>
													)}
												</div>
												{accumulatedMetrics && (
													<div className="mt-2">
														{!metricsLoading && (
															<div className="text-gray-500">
																<p>
																	Organization weekly {goal.metric_type} in the
																	last 3 months:{" "}
																	<b>
																		{Math.floor(
																			accumulatedMetrics[
																				goal.metric_type as keyof PredictedMetricValues
																			],
																		) || 0}
																	</b>
																</p>
															</div>
														)}
														{weeklyGrowthRate?.[
															goal.metric_type as keyof GrowthMetrics
														] && (
															<div className="text-gray-500">
																<p>
																	Weekly growth needed for {goal.metric_type}:{" "}
																	<b>
																		{weeklyGrowthRate[
																			goal.metric_type as keyof GrowthMetrics
																		]?.toFixed(2) || 0}
																	</b>
																</p>
															</div>
														)}
													</div>
												)}
											</div>
										),
								)}
							</div>
						</TabsContent>
					</Tabs>
				</div>

				<Button
					color="blue"
					action={handleSubmit(onSubmit)}
					className="w-full"
					loading={loading}
					text="Create Goal"
				/>
			</form>
		</main>
	);
};
