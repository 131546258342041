import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { useState } from "react";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import type { User } from "../_hooks/use-organization-users";
import { SkeletonMetricCard } from "./skeleton-metric-card";

interface MetricsData {
	posts: number[];
	interactions: number[];
	engagement: number[];
	comments: number[];
	shares: number[];
	reach: number[];
	blogs: number[];
}

interface MetricCardProps {
	title: string;
	value: number;
	change: number;
	metricData: number[];
	onSelect: (metric: string) => void;
	isSelected: boolean;
}

function MetricCard({
	title,
	value,
	change,
	metricData,
	onSelect,
	isSelected,
}: MetricCardProps) {
	const chartData = metricData.map((value, index) => ({ value, index }));

	return (
		<Card
			className={`cursor-pointer hover:shadow-lg transition-shadow bg-white ${
				isSelected ? "ring-2 ring-primary" : ""
			}`}
			onClick={() => onSelect(title)}
		>
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
				<CardTitle className="text-sm font-medium">{title}</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="text-2xl font-bold">{value.toLocaleString()}</div>
				<div className="flex items-center space-x-2">
					{change > 0 ? (
						<ArrowUpIcon className="w-4 h-4 text-emerald-500" />
					) : (
						<ArrowDownIcon className="w-4 h-4 text-red-500" />
					)}
					<span className={change > 0 ? "text-emerald-500" : "text-red-500"}>
						{Math.abs(change)}%
					</span>
					<span className="text-muted-foreground">vs. previous period</span>
				</div>
				<div className="h-[40px] mt-4">
					<ResponsiveContainer width="100%" height="100%">
						<LineChart data={chartData}>
							<Line
								type="monotone"
								dataKey="value"
								stroke={change > 0 ? "#10b981" : "#ef4444"}
								strokeWidth={1.5}
								dot={false}
							/>
						</LineChart>
					</ResponsiveContainer>
				</div>
			</CardContent>
		</Card>
	);
}

interface MetricCardsProps {
	user: User | undefined;
	loading?: boolean;
	selectedMetric: string | null;
	onMetricSelect: (metric: string) => void;
	metricsData: MetricsData;
}

export const MetricCards = ({
	user,
	loading,
	selectedMetric,
	onMetricSelect,
	metricsData,
}: MetricCardsProps) => {
	if (loading || !user) {
		return (
			<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
				{Array.from({ length: 8 }).map(() => (
					<SkeletonMetricCard key={crypto.randomUUID()} />
				))}
			</div>
		);
	}

	const metrics = [
		{
			title: "Followers",
			value: user.followers,
			change: user.followersChange,
			data: metricsData?.reach || [], // Using reach as proxy for followers
		},
		{
			title: "Engagement",
			value: user.engagement,
			change: user.engagementChange,
			data: metricsData?.engagement || [],
		},
		{
			title: "Posts",
			value: user.posts,
			change: user.postsChange,
			data: metricsData?.posts || [],
		},
		{
			title: "Comments",
			value: user.comments,
			change: user.commentsChange,
			data: metricsData?.comments || [],
		},
		{
			title: "Reach",
			value: user.reach,
			change: user.reachChange,
			data: metricsData?.reach || [],
		},
		{
			title: "Interactions",
			value: user.interactions,
			change: user.interactionsChange,
			// Sum of likes, comments, and shares for each day
			data: metricsData?.interactions || [],
		},
		{
			title: "Shares",
			value: user.shares,
			change: user.sharesChange,
			data: metricsData?.shares || [],
		},
		{
			title: "Blogs",
			value: user.blogs,
			change: user.blogsChange,
			data: metricsData?.blogs || [],
		},
	];

	return (
		<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
			{metrics.map((metric) => (
				<MetricCard
					key={metric.title}
					title={metric.title}
					value={metric.value}
					change={metric.change}
					metricData={metric.data}
					onSelect={onMetricSelect}
					isSelected={selectedMetric === metric.title}
				/>
			))}
		</div>
	);
};
