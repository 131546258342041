import axios from "axios";
import { Animate, AuthContext } from "components/lib";
import { useAPI } from "components/lib";
import { Badge } from "components/ui/badge";
import { Card } from "components/ui/card";
import { DatePickerWithRange } from "components/ui/date-range-picker";
import { Label } from "components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Switch } from "components/ui/switch";
import { endOfDay, format, subDays } from "date-fns";
import { useContext, useEffect, useMemo, useState } from "react";

import CollaboratorMetricsTableVsGoals from "./_components/collaborator-metrics-table-vs-goals";
import CollaboratorsMetricsCharts from "./_components/collaborators-metrics-charts";
import CollaboratorMetricsTable from "./_components/collaborators-metrics-table";
import ExtensionMessage from "./_components/extension-message";
import { MetricsPieCharts } from "./_components/metrics-pie-charts";
import { MetricsPieChartsVsGoals } from "./_components/metrics-pie-charts-vs-goals";
import { SkeletonSelect } from "./_components/skeleton-select";
import { SkeletonTable } from "./_components/skeleton-table";
import { TeamSelect } from "./_components/team-select";
import { sortedTableTimeFrameOptions } from "./utils";

function filterDataByTeam(
	selectedTeamIds,
	weekProgressData,
	userInitialGoalMetrics,
) {
	if (!selectedTeamIds || selectedTeamIds.length === 0) {
		return {
			filteredWeekProgressData: weekProgressData,
			filteredUserInitialGoalMetrics: userInitialGoalMetrics,
		};
	}

	// Filter weekProgressData
	const filteredWeekProgressData = weekProgressData.map((weekData) => ({
		...weekData,
		reach: weekData.reach.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
		likes: weekData.likes.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
		comments: weekData.comments.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
		shares: weekData.shares.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
		posts: weekData.posts.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
	}));

	// Filter userInitialGoalMetrics
	const filteredUserInitialGoalMetrics = userInitialGoalMetrics.map(
		(metric) => ({
			...metric,
			data: metric.data.filter((item) =>
				selectedTeamIds.includes(item.user_id),
			),
		}),
	);

	return { filteredWeekProgressData, filteredUserInitialGoalMetrics };
}

export function Dashboard(props) {
	const auth = useContext(AuthContext);

	const organizationId = auth?.user?.organization_id;

	const [timeFrame, setTimeFrame] = useState(sortedTableTimeFrameOptions[1]);
	const [dateRange, setDateRange] = useState({ from: null, to: null });
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [customDateLabel, setCustomDateLabel] = useState("");

	const [vsGoal, setVsGoal] = useState(false);

	const [selectedGoal, setSelectedGoal] = useState(null);
	const [goalMetrics, setGoalMetrics] = useState([]);
	const [userInitialGoalMetrics, setUserInitialGoalMetrics] = useState([]);
	const [weekProgressData, setWeekProgressData] = useState([]);
	const [goalDataLoading, setGoalDataLoading] = useState(true);
	const [selectedWeek, setSelectedWeek] = useState(null);

	const [selectedTeamId, setSelectedTeamId] = useState(null);
	const [teamUsers, setTeamUsers] = useState({});

	const [teamsData, setTeamsData] = useState(null);
	const [teamsLoading, setTeamsLoading] = useState(true);
	const [teamsError, setTeamsError] = useState(null);

	const startDate = useMemo(() => {
		return timeFrame.value !== "custom"
			? format(subDays(new Date(), timeFrame.value), "yyyy-MM-dd'T'HH:mm:ssxxx")
			: format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ssxxx");
	}, [timeFrame, dateRange.from]);

	const endDate = useMemo(() => {
		return timeFrame.value !== "custom"
			? format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
			: format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ssxxx");
	}, [timeFrame, dateRange.to]);

	const {
		data: metrics,
		loading: metricsLoading,
		error,
	} = useAPI(
		organizationId
			? `/api/analytics/${organizationId}/collaborators?startDate=${startDate}&endDate=${endDate}&type=${timeFrame.type ? timeFrame.type : "days"}`
			: null,
	);

	const {
		data: usersSyncStatus,
		loading: usersSyncStatusLoading,
		error: usersSyncStatusError,
	} = useAPI(
		organizationId
			? `/api/analytics/${organizationId}/users-sync-status`
			: null,
	);

	const {
		data: goals,
		loading: goalsLoading,
		error: goalsError,
	} = useAPI(
		organizationId
			? `/api/goal/get-all?organization_id=${organizationId}`
			: null,
	);

	useEffect(() => {
		if (organizationId) {
			setTeamsLoading(true);
			axios
				.get(`/api/team/${organizationId}`)
				.then((response) => {
					setTeamsData(response.data);
					setTeamsLoading(false);
				})
				.catch((error) => {
					setTeamsError(error);
					setTeamsLoading(false);
				});
		}
	}, [organizationId]);

	if (!organizationId) {
		return null;
	}

	const handleTimeFrameChange = (selectedTimeFrame) => {
		if (selectedTimeFrame.value === "custom") {
			setShowDatePicker(true);
		} else {
			setShowDatePicker(false);
			setTimeFrame(selectedTimeFrame);
			setCustomDateLabel("");
			setDateRange({ from: null, to: null });
		}
	};

	useEffect(() => {
		if (organizationId && !goalsLoading && !goalsError && goals) {
			setSelectedGoal(goals[0]);
		}
	}, [organizationId, goalsLoading, goalsError, goals]);

	// Fetch metrics data when goal is loaded
	useEffect(() => {
		if (selectedGoal) {
			setGoalDataLoading(true);
			axios
				.get(`/api/goal/get-goal-metrics?goal_id=${selectedGoal.id}`)
				.then((response) => {
					setGoalMetrics(response.data.data);
				})
				.catch((error) => {
					console.error("Error fetching metrics:", error);
				});
		}
	}, [selectedGoal]);

	// Concatenate metricTypes to form metricsQuery
	useEffect(() => {
		if (goalMetrics?.length) {
			const initialGoalMetrics = [];
			const goalMetricIds = goalMetrics.map((metric) => metric.id).join(",");
			axios
				.get(
					`/api/goal/get-user-initial-goal-metrics?goal_metric_ids=${goalMetricIds}`,
				)
				.then((response) => {
					const goalMetricsData = response.data.data;

					for (const goalMetric of goalMetrics) {
						const filteredData = goalMetricsData.filter(
							(data) => data.goal_metric_id === goalMetric.id,
						);
						initialGoalMetrics.push({
							metricType: goalMetric.metric_type,
							weeklyGrowthRate: goalMetric.weekly_growth_rate,
							data: filteredData.map((data) => ({
								user_id: data.user_id,
								initial_value: data.initial_value,
								start_date: data.start_date,
							})),
						});
					}

					setUserInitialGoalMetrics(initialGoalMetrics);
				})
				.catch((error) => {
					console.error("Error fetching user initial goal metrics:", error);
				});
		}
	}, [goalMetrics]);

	useEffect(() => {
		if (
			selectedGoal &&
			goalMetrics &&
			goalMetrics.length &&
			userInitialGoalMetrics &&
			userInitialGoalMetrics.length
		) {
			const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
			const metricTypes = goalMetrics
				.map((metric) => metric.metric_type)
				.join(",");
			axios
				.get(
					`/api/goal/get-current-users-progress?goal_id=${selectedGoal.id}&start_date=${selectedGoal.start_date}&end_date=${now}&metrics=${metricTypes}`,
				)
				.then((response) => {
					const responseData = response.data.data;
					setWeekProgressData(responseData);
					setSelectedWeek(responseData[responseData.length - 1].week);
					setGoalDataLoading(false);
				})
				.catch((error) => {
					console.error("Error fetching metrics:", error);
					setGoalDataLoading(false);
				});
		}
	}, [selectedGoal, goalMetrics, userInitialGoalMetrics]);

	// Fetch team users when teams data is loaded
	useEffect(() => {
		if (teamsData) {
			const fetchTeamUsers = async () => {
				const usersPromises = teamsData.map((team) =>
					axios.get(`/api/team/${team.id}/users`),
				);
				const usersResponses = await Promise.all(usersPromises);
				const teamUsersMap = {};
				teamsData.forEach((team, index) => {
					teamUsersMap[team.id] = usersResponses[index].data;
				});
				setTeamUsers(teamUsersMap);
			};
			fetchTeamUsers();
		}
	}, [teamsData]);

	// Filter metrics based on selected team
	const filteredMetrics = useMemo(() => {
		if (!selectedTeamId || !teamUsers[selectedTeamId]) {
			return metrics;
		}
		const teamUserIds = teamUsers[selectedTeamId].map((user) => user.id);
		return metrics.filter((metric) => teamUserIds.includes(metric.user_id));
	}, [metrics, selectedTeamId, teamUsers]);

	const { filteredWeekProgressData, filteredUserInitialGoalMetrics } =
		filterDataByTeam(
			selectedTeamId ? teamUsers[selectedTeamId].map((user) => user.id) : [],
			weekProgressData,
			userInitialGoalMetrics,
		);

	return (
		<Animate type="pop">
			<ExtensionMessage />
			<div className="flex flex-col gap-8 h-full">
				<div className="flex justify-between items-center w-full">
					{vsGoal ? (
						<MetricsPieChartsVsGoals
							weekProgressData={filteredWeekProgressData}
							userInitialGoalMetrics={filteredUserInitialGoalMetrics}
							loading={goalDataLoading}
							selectedWeek={selectedWeek}
							goalMetrics={goalMetrics}
							goal={selectedGoal}
							selectedTeamId={selectedTeamId}
						/>
					) : (
						<MetricsPieCharts
							metrics={filteredMetrics}
							loading={metricsLoading}
						/>
					)}
				</div>
				<Card
					title={props.t("dashboard.collaborator_metrics.title")}
					className="min-h-[25rem] bg-white p-6 w-full"
					last
				>
					<div className="flex gap-4">
						{metricsLoading ? (
							<SkeletonSelect />
						) : (
							<>
								<Select
									value={customDateLabel ? "custom" : timeFrame.value}
									onValueChange={(value) =>
										handleTimeFrameChange(
											sortedTableTimeFrameOptions.find(
												(option) => option.value === value,
											),
										)
									}
									disabled={vsGoal}
								>
									<SelectTrigger className="max-w-60 bg-white">
										<SelectValue>
											{customDateLabel || props.t(timeFrame.label)}
										</SelectValue>
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											<SelectLabel>Time Frames</SelectLabel>
											{sortedTableTimeFrameOptions.map((option) => (
												<SelectItem key={option.value} value={option.value}>
													{props.t(option.label)}
												</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
								{showDatePicker && (
									<DatePickerWithRange
										from={dateRange.from}
										to={dateRange.to}
										onDateChange={({ from, to }) => {
											const endOfTo = endOfDay(to);
											setDateRange({ from, to: endOfTo });
											const customLabel = `${format(from, "MMM d, yyyy")} - ${format(endOfTo, "MMM d, yyyy")}`;
											setCustomDateLabel(customLabel);
											setTimeFrame({
												label: customLabel,
												value: "custom",
												type: "custom",
											});
											setShowDatePicker(false);
										}}
										disabled={vsGoal}
									/>
								)}

								{!goalsLoading &&
									goals?.length > 0 &&
									weekProgressData?.length > 0 && (
										<>
											<Badge className="bg-white shadow-sm" variant="outline">
												<div className="flex items-center space-x-2 w-full">
													<Switch
														id="switch"
														checked={vsGoal}
														onCheckedChange={setVsGoal}
													/>
													<Label htmlFor="switch">
														{props.t("dashboard.collaborator_metrics.vs_goal")}
													</Label>
												</div>
											</Badge>
											<Select
												value={selectedGoal}
												onValueChange={setSelectedGoal}
												disabled={!vsGoal}
											>
												<SelectTrigger className="max-w-36 bg-white">
													<SelectValue placeholder="Select a goal" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectLabel>All Goals</SelectLabel>
														{goals.map((goal) => (
															<SelectItem key={goal.id} value={goal}>
																{goal.title}
															</SelectItem>
														))}
													</SelectGroup>
												</SelectContent>
											</Select>
											<Select
												value={selectedWeek}
												onValueChange={(week) => setSelectedWeek(week)}
												disabled={!vsGoal}
											>
												<SelectTrigger className="max-w-36 bg-white">
													<SelectValue placeholder="Select a week" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectLabel>Weeks in Goal</SelectLabel>
														{weekProgressData.map((week) => (
															<SelectItem key={week.week} value={week.week}>
																Week {week.week}
															</SelectItem>
														))}
													</SelectGroup>
												</SelectContent>
											</Select>
										</>
									)}
							</>
						)}

						{!teamsLoading && teamsData && !metricsLoading ? (
							<TeamSelect
								teams={teamsData}
								selectedTeamId={selectedTeamId}
								onSelectTeam={setSelectedTeamId}
								disabled={teamsLoading}
							/>
						) : (
							<SkeletonSelect />
						)}
					</div>
					{vsGoal ? (
						goalDataLoading ? (
							<SkeletonTable />
						) : (
							filteredWeekProgressData &&
							filteredUserInitialGoalMetrics && (
								<CollaboratorMetricsTableVsGoals
									weekProgressData={filteredWeekProgressData}
									userInitialGoalMetrics={filteredUserInitialGoalMetrics}
									selectedWeek={selectedWeek}
									goalMetrics={goalMetrics}
									goal={selectedGoal}
									selectedTeamId={selectedTeamId}
									usersSyncStatus={usersSyncStatus}
									t={props.t}
								/>
							)
						)
					) : metricsLoading && usersSyncStatusLoading ? (
						<SkeletonTable />
					) : (
						filteredMetrics &&
						usersSyncStatus && (
							<CollaboratorMetricsTable
								metrics={filteredMetrics}
								t={props.t}
								usersSyncStatus={usersSyncStatus}
							/>
						)
					)}
				</Card>
				<Card
					title={props.t("dashboard.collaborator_metrics.charts_title")}
					className="min-h-[20rem] bg-white p-4"
				>
					<CollaboratorsMetricsCharts
						organizationId={organizationId}
						t={props.t}
						weeklyProgressData={weekProgressData}
						goalMetrics={goalMetrics}
						goal={selectedGoal}
						userInitialGoalMetrics={userInitialGoalMetrics}
						selectedTeamId={selectedTeamId}
					/>
				</Card>
			</div>
		</Animate>
	);
}
