/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable node/no-unsupported-features/es-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import "@blocknote/mantine/style.css";

import { BlockNoteView } from "@blocknote/mantine";
import { useCreateBlockNote } from "@blocknote/react";
import { Link } from "@nextui-org/react";
import { Typography } from "antd";
import { buttonVariants } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { htmlToGutenbergBlocks } from "lib/utils";
import { measureTime } from "../_utils/linkedin";
import { appConfig } from "../config";
import CustomVoicesSelect from "../custom-voice/components/customVoicesSelect";
import type {
	BlogPostParameters,
	LinkedinPostType,
	WordPressBlogPost,
} from "../types";
import { AsyncButton } from "./async-button";
import { LinkedinPost } from "./linkedin-post";

interface PostGeneratorProps {
	post: LinkedinPostType;
	userId: string;
	organizationId: string;
	wordpressConfig:
		| {
				username?: string;
				applicationPassword?: string;
				webUrl?: string;
		  }
		| undefined;
	wordpressUser:
		| {
				id?: string;
				username?: string;
				email?: string;
				name?: string;
		  }
		| undefined;
	t: (path: string) => string;
}

const analyzeLinkedinPost = async (post: LinkedinPostType): Promise<any> => {
	const data = await fetch(
		`${appConfig.API_MAIN_URL}/api/content-creation/generate/analysis`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(post),
		},
	);

	return await data.json();
};

export function PostGenerator({
	post,
	wordpressConfig,
	wordpressUser,
	userId,
	organizationId,
	t,
}: PostGeneratorProps) {
	const posthog = usePostHog();
	const [blogPost, setBlogPost] = useState<WordPressBlogPost>();
	const [blogPostUrl, setBlogPostUrl] = useState<string>();
	const [blogPostGenerationState, setBlogPostGenerationState] = useState<
		| "not_triggered"
		| "analyzing"
		| "generating"
		| "enhancing"
		| "publishing"
		| "done"
	>("not_triggered");
	const [parameters, setParameters] = useState<BlogPostParameters>();
	const [voiceId, setVoiceId] = useState<number | null>(null);
	const editor = useCreateBlockNote();

	const expandLinkedinPostIntoBlogPostGPT = async (
		blogPostParameters: string,
		linkedinPost: LinkedinPostType,
		metadata: WordPressBlogPost,
		customVoiceId: number | null,
	) => {
		await measureTime(async () => {
			const response = await fetch(
				`${appConfig.API_MAIN_URL}/api/content-creation/generate/stream`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						content: linkedinPost.content,
						blogParams: blogPostParameters,
						customVoiceId: customVoiceId,
					}),
				},
			);

			let completeText = "";
			if (response.body) {
				const reader = response.body.getReader();
				const chunks = [];

				// eslint-disable-next-line no-constant-condition
				while (true) {
					const { done, value } = await reader.read();

					if (done) {
						break;
					}

					chunks.push(value);

					// eslint-disable-next-line node/no-unsupported-features/node-builtins
					const textChunk = new TextDecoder("utf8").decode(value, {
						stream: true,
					});

					completeText += textChunk;

					setBlogPost({
						...metadata,
						content: completeText,
					});
				}
			}
		});
	};

	const createLinkedinAnalisis = async (post: LinkedinPostType) => {
		const [postAnalysis] = await measureTime(
			async () => await analyzeLinkedinPost(post),
		);

		const parametersObject = Object.fromEntries(
			Object.entries(postAnalysis).map(([key, value]: [string, unknown]) => [
				key,
				{ label: key, value: String(value) },
			]),
		);
		setParameters(parametersObject);
	};

	const expandLinkedinPostIntoBlogPost = async (
		post: LinkedinPostType,
	): Promise<void> => {
		const blogParameters = parameters
			? Object.fromEntries(
					Object.entries(parameters).map(([key, value]) => [key, value.value]),
				)
			: { keyQuestions: "" };
		if (typeof blogParameters.keyQuestions === "string") {
			blogParameters.keyQuestions = blogParameters.keyQuestions.split(
				",",
			) as any;
		}

		const blogParams = JSON.stringify(blogParameters);
		setBlogPostGenerationState("generating");

		const blogMetadataResponse = await fetch(
			`${appConfig.API_MAIN_URL}/api/content-creation/generate/metadata`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					content: post.content,
					blogParams: blogParams,
				}),
			},
		);

		const blogMetadata = await blogMetadataResponse.json();

		await expandLinkedinPostIntoBlogPostGPT(
			blogParams,
			post,
			blogMetadata,
			voiceId,
		);
		setBlogPostGenerationState("done");
	};

	const publishToWordPress = async (blogPost: WordPressBlogPost) => {
		const html = await editor.blocksToHTMLLossy(editor.document);

		const gutenbergBlocks = htmlToGutenbergBlocks(html);

		const body = {
			content: gutenbergBlocks,
			title: blogPost.title,
			excerpt: blogPost.excerpt,
			slug: blogPost.slug,
			author: wordpressUser?.id,
			date_gmt: post.creation_time,
			featured_image: post.image_id
				? `https://media.licdn.com/dms/image/${post.image_id}`
				: null,
			domain: wordpressConfig?.webUrl,
			wordpress_application_password_key: wordpressConfig?.applicationPassword,
			wordpress_username: wordpressConfig?.username,
			status: "draft",
		};

		posthog.capture("blog_from_linkedin_post_flow_finished", {
			post: JSON.stringify(body),
		});

		const response = await fetch(
			`${appConfig.API_MAIN_URL}/api/wordpress/publish-blogpost`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
			},
		);

		const data: { id: number } = await response.json();

		setBlogPostUrl(
			`https://${wordpressConfig?.webUrl}/wp-admin/post.php?post=${data.id}&action=edit`,
		);
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <if we change them we would set the same content again>
	useEffect(() => {
		async function loadInitialStreamResponse() {
			const blocks = await editor.tryParseHTMLToBlocks(blogPost?.content || "");
			editor.replaceBlocks(editor.document, blocks);
		}
		loadInitialStreamResponse();
	}, [blogPost?.content]);

	const inputs = parameters
		? Object.keys(parameters).map((key) => {
				const parameter = parameters[key as keyof BlogPostParameters];
				return (
					<div key={`${parameter.label}-${key}`}>
						<Label>{parameter.label}</Label>
						<Input
							key={key}
							value={parameter.value}
							onChange={(e) =>
								setParameters({
									...parameters,
									[key]: { ...parameter, value: e.target.value },
								})
							}
						/>
					</div>
				);
			})
		: [];

	const blogPostGenerationStateText = () => {
		switch (blogPostGenerationState) {
			case "not_triggered": {
				return t("content-creation.post_generator.create_blog");
			}
			case "analyzing": {
				return "Analizando Post de LinkedIn";
			}
			case "generating": {
				return t("content-creation.post_generator.generating_blog");
			}
			case "enhancing": {
				return "Mejorando Blog Post";
			}
			case "publishing": {
				return t("content-creation.post_generator.publishing_blog");
			}
			case "done": {
				return t("content-creation.post_generator.regenerate_blog");
			}
		}
	};

	return (
		<div className="flex-1">
			<ToastContainer />
			<h1 className="text-2xl font-bold text-center text-blue-500">
				Post Generator
			</h1>
			<div className="card">
				<div className="flex flex-col justify-end space-y-4">
					<LinkedinPost
						post={post}
						canCreate={false}
						t={t}
						userId={userId}
						organizationId={organizationId}
					/>
					<CustomVoicesSelect
						userId={userId}
						organizationId={organizationId}
						handleState={setVoiceId}
					/>
					{inputs}
					{!parameters && (
						<AsyncButton
							className={buttonVariants()}
							errorMessage={t(
								"content-creation.post_generator.error_generating_blog",
							)}
							label={t("content-creation.post_generator.analyze_post")}
							asyncFunction={async () => createLinkedinAnalisis(post)}
						/>
					)}
					{parameters && (
						<AsyncButton
							errorMessage={t(
								"content-creation.post_generator.error_generating_blog",
							)}
							label={blogPostGenerationStateText()}
							asyncFunction={async () => expandLinkedinPostIntoBlogPost(post)}
						/>
					)}
				</div>
			</div>
			{blogPost && (
				<div className="flex-1">
					<div className="mb-5 w-full">
						<Typography.Title
							level={3}
							style={{ marginBottom: "1rem", marginTop: "1rem" }}
							editable={{
								onChange: (newTitle) =>
									setBlogPost({ ...blogPost, title: newTitle }),
							}}
						>
							{blogPost.title}
						</Typography.Title>
						<BlockNoteView editor={editor} />
					</div>
					{blogPostUrl && (
						<div className="flex flex-col justify-center items-center show-blog-from-linkedin-post-survey">
							<Link
								className="mb-5"
								isBlock
								isExternal
								showAnchorIcon
								href={blogPostUrl}
								color="primary"
							>
								{t("content-creation.post_generator.go_to_generated_blog")}
							</Link>
						</div>
					)}
					<AsyncButton
						className="mb-5"
						variant="default"
						errorMessage={t(
							"content-creation.post_generator.error_publishing_blog",
						)}
						successMessage={t("content-creation.post_generator.published_blog")}
						label={t("content-creation.post_generator.publish_blog")}
						asyncFunction={async () => publishToWordPress(blogPost)}
					/>
				</div>
			)}
		</div>
	);
}
