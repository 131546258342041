/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useQuery } from "@tanstack/react-query";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { appConfig } from "views/content-creation/config";

import type { WordpressCredentialsState } from "./wordpress-credentials";

export type WordPressUser = {
	id: string;
	username: string;
	email?: string | null;
	name: string;
	userId?: string | null;
};

const fetchWordPressUsers = async (
	credentials: WordpressCredentialsState | null,
): Promise<WordPressUser[]> => {
	if (!credentials) {
		throw new Error("No WordPress credentials provided");
	}

	const response = await fetch(
		`${appConfig.API_MAIN_URL}/api/wordpress/get/users`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				domain: credentials.webUrl,
				wordpress_username: credentials.username,
				wordpress_application_password_key: credentials.applicationPassword,
			}),
		},
	);
	if (!response.ok) {
		throw new Error(
			"Wrong credentials. Check this tutorial for application passwords: https://www.nextsoftwaresolutions.com/kb/create-application-passwords-in-wordpress/",
		);
	}
	return response.json() as any;
};

type WordPressUsersProps = {
	credentials: WordpressCredentialsState | null;
	setWordPressUser: (user: WordPressUser | undefined) => void;
};

export const WordPressUsers: React.FC<WordPressUsersProps> = ({
	credentials,
	setWordPressUser,
}) => {
	const {
		data: users,
		isLoading,
		isError,
	} = useQuery({
		queryKey: ["wordPressUsers", credentials?.webUrl],
		queryFn: () => {
			if (credentials) {
				return fetchWordPressUsers(credentials);
			}
			return [];
		},
	});

	const handleSelectChange = (value: string) => {
		const user = users?.find((u) => `${u.username}` === value);
		if (user) {
			setWordPressUser(user);
		}
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (isError) {
		return <div>Error fetching WordPress users</div>;
	}

	return (
		<>
			<Select onValueChange={handleSelectChange}>
				<SelectTrigger className="w-[280px]">
					<SelectValue placeholder="Select WordPress User" />
				</SelectTrigger>
				<SelectContent>
					{users?.map((user) => (
						<SelectItem key={`${user.username}`} value={`${user.username}`}>
							{user.name}
						</SelectItem>
					))}
				</SelectContent>
			</Select>
		</>
	);
};
