import {
	keepPreviousData,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { AuthContext, useNavigate } from "components/lib";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "components/ui/accordion";
import { Button } from "components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "components/ui/collapsible";
import { FileUploader } from "components/ui/file-uploader";
import { FormField, FormItem } from "components/ui/form";
import { Input } from "components/ui/input";
import MultiStepForm, {
	type MultiFormStep,
} from "components/ui/multi-step-form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Textarea } from "components/ui/textarea";
import { ES, US } from "country-flag-icons/react/3x2";
import { diffWords } from "diff";
import { useAnalytics } from "lib/analytics";
import { htmlToGutenbergBlocks, interactWithExtension } from "lib/utils";
import {
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	ChevronUp,
	Copy,
	Plus,
	Trash2,
} from "lucide-react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
	type Control,
	type FieldErrors,
	type UseFormRegister,
	type UseFormSetValue,
	useFieldArray,
	useWatch,
} from "react-hook-form";
import { appConfig } from "views/content-creation/config";
import { z } from "zod";
import type { GetMorePostsFetchType, LinkedinPostType } from "../../../types";
import { LinkedinRefreshAlert } from "../../linkedin-refresh-alert";
import { LinkedinRefreshButton } from "../../linkedin-refresh-button";
import TextEditorWithReadability from "../../text-editor-with-benchmarks";
import type { WordPressCredentials as WordPressCredentialsType } from "../WordpressBlogFromLinkedinPost/types";
import {
	WordPressCredentials,
	type WordpressCredentialsState,
} from "../WordpressBlogFromLinkedinPost/wordpress-credentials";
import type { WordPressUser } from "../WordpressBlogFromLinkedinPost/wordpress-users";
import { LinkedinPost } from "./linkedin-post";

// Add this utility function at the top of the file, outside of any component
function stripHtml(html: string): string {
	const tmp = document.createElement("div");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
}

const mode = import.meta.env.MODE as "development" | "production";

const Step1Schema = z
	.object({
		files: z.array(z.instanceof(File)).optional(),
		text: z.string().optional(),
		url: z.string().url().optional().or(z.literal("")),
		youtubeUrl: z
			.string()
			.url()
			.regex(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/)
			.optional()
			.or(z.literal("")),
		linkedinPosts: z.array(z.string()).optional(),
		personalOpinion: z.string().optional(),
		domain: z.object({
			id: z.number(),
			domain: z.string(),
			organizationId: z.string().nullable(),
		}),
		wpUser: z.object({
			id: z.string(),
			username: z.string(),
			name: z.string(),
			email: z.string().nullable(),
			userId: z.string().nullable(),
		}),
		language: z.enum(["es", "en"]).default("es"),
	})
	.refine(
		(data) =>
			(data.files?.length ?? 0) > 0 ||
			(data.text?.length ?? 0) > 0 ||
			(data.url?.length ?? 0) > 0 ||
			(data.youtubeUrl?.length ?? 0) > 0 ||
			(data.linkedinPosts?.length ?? 0) > 0,
		{
			message:
				"At least one file, some text, a YouTube URL, a website URL or a Linkedin Post is required",
			path: ["files"],
		},
	);

const Step2Schema = z.object({
	selectedType: z.string().min(1, "Please select a blog type"),
	combinedContent: z.string().min(1, "Please send the blog content"),
	selectedIdea: z.string().min(1, "Please select a blog idea"),
	personalOpinion: z.string().optional(),
});

const Step3Schema = z.object({
	outline: z
		.array(
			z.object({
				title: z.string().min(1, "Outline item cannot be empty"),
				level: z.enum(["2", "3"]),
			}),
		)
		.min(1, "At least one outline item is required"),
	keywords: z.array(z.string()).min(1, "At least one keyword is required"),
	combinedContent: z.string().min(1, "Please send the blog content"),
	externalDomainPostsData: z.array(
		z.object({
			url: z.string(),
			data: z.array(z.string()),
			domain: z.string(),
		}),
	),
	internalDomainPostsData: z.array(
		z.object({
			url: z.string(),
			data: z.array(z.string()),
			domain: z.string(),
			blogConcept: z.string(),
		}),
	),
	personalOpinion: z.string().optional(),
	useEfficientModel: z.boolean().optional(),
});

const Step4Schema = z.object({
	content: z.string().min(1, "Please review and edit your blog post"),
	readabilityMetrics: z
		.array(
			z.object({
				name: z.string(),
				description: z.string(),
				value: z.number(),
				maxValue: z.number().optional(),
			}),
		)
		.optional(),
	generatedBlog: z.string(),
	startTime: z.number().optional(),
	wordpressCredentials: z.array(
		z.object({
			applicationPassword: z.string(),
			username: z.string(),
		}),
	),
});

const Step6Schema = z.object({});

type ContentTranscription = {
	name: string;
	transcription: string;
};

const getBlogTypes = (t: (key: string) => string): BlogType[] => [
	{
		type: "howto",
		title: t("content-creation.blog_post_from_multimedia.blog_type.howto"),
	},
	{
		type: "alternatives",
		title: t(
			"content-creation.blog_post_from_multimedia.blog_type.alternatives",
		),
	},
	{
		type: "comparisons",
		title: t(
			"content-creation.blog_post_from_multimedia.blog_type.comparisons",
		),
	},
	{
		type: "typesof",
		title: t("content-creation.blog_post_from_multimedia.blog_type.typesof"),
	},
	{
		type: "resources",
		title: t("content-creation.blog_post_from_multimedia.blog_type.resources"),
	},
];

type Domain = {
	id: number;
	domain: string;
	organizationId?: string;
};

type BlogType = {
	type: "howto" | "alternatives" | "comparisons" | "typesof" | "resources";
	title: string;
};

type BlogIdea = {
	idea: string;
	content: string;
};

type BlogRecommendation = {
	ideas: BlogIdea[];
};

type BlogRecommendations = {
	recommendations: {
		howto: BlogRecommendation;
		alternatives: BlogRecommendation;
		comparisons: BlogRecommendation;
		typesof: BlogRecommendation;
		resources: BlogRecommendation;
	};
	recommendedType:
		| "howto"
		| "alternatives"
		| "comparisons"
		| "typesof"
		| "resources";
};

type OutlineData = z.infer<typeof Step3Schema>;

const Step2: React.FC<{
	register: UseFormRegister<z.infer<typeof Step2Schema>>;
	errors: FieldErrors<z.infer<typeof Step2Schema>>;
	control: Control<z.infer<typeof Step2Schema>>;
	metadata: [
		{
			recommendations: BlogRecommendations;
			contentTranscriptions: ContentTranscription[];
			combinedContent: string;
			personalOpinion?: string;
		},
	];
	setValue: UseFormSetValue<z.infer<typeof Step2Schema>>;
	t: (key: string) => string;
}> = ({ errors, control, metadata, setValue, t }) => {
	const recommendations = metadata[0].recommendations as BlogRecommendations;
	const recommendedType = recommendations.recommendedType;
	const combinedContent = metadata[0].combinedContent as string;
	const contentTranscriptions = metadata[0]
		.contentTranscriptions as ContentTranscription[];
	const [selectedType, setSelectedType] = useState<string>(recommendedType);
	const [selectedIdeaIndexes, setSelectedIdeaIndexes] = useState<
		Record<string, number>
	>({});
	const [expandedTranscriptions, setExpandedTranscriptions] = useState<
		Record<string, boolean>
	>({});
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const auth: any = useContext(AuthContext);
	const organizationId = auth.user?.organization_id;
	const navigate = useNavigate();
	const { toast } = useToast();

	const personalOpinion = metadata[0].personalOpinion;

	useEffect(() => {
		setValue("combinedContent", combinedContent);
		setValue("selectedType", recommendedType);
		if (personalOpinion) {
			setValue("personalOpinion", personalOpinion);
		}

		const initialIdea =
			recommendations.recommendations[recommendedType]?.ideas[0]?.idea;
		if (initialIdea) {
			setValue("selectedIdea", initialIdea);
		}
	}, [
		combinedContent,
		setValue,
		recommendations,
		recommendedType,
		personalOpinion,
	]);

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		toast({
			title: "Copied to clipboard",
			description: "The content has been copied to your clipboard.",
		});
	};

	const toggleTranscription = (name: string) => {
		setExpandedTranscriptions((prev) => ({
			...prev,
			[name]: !prev[name],
		}));
	};

	return (
		<Card className="mx-auto w-full">
			<CardHeader>
				<CardTitle>
					{t("content-creation.blog_post_from_multimedia.blog_type.title")}
				</CardTitle>
				<CardDescription>
					{t(
						"content-creation.blog_post_from_multimedia.blog_type.description",
					)}
				</CardDescription>
			</CardHeader>
			<CardContent>
				<FormField
					control={control}
					name="selectedType"
					render={({ field }) => (
						<FormItem className="space-y-3">
							<div className="grid grid-cols-1 gap-4">
								{getBlogTypes(t).map((blogType) => {
									const blogTypeRecommendations =
										recommendations.recommendations[blogType.type];
									const ideas = blogTypeRecommendations?.ideas || [];
									const selectedIndex = selectedIdeaIndexes[blogType.type] || 0;
									const selectedIdea = ideas[selectedIndex];
									const [isCustomIdea, setIsCustomIdea] = useState(false);
									const [customIdea, setCustomIdea] = useState("");

									return (
										<Card
											key={blogType.type}
											className={`relative cursor-pointer overflow-hidden ${
												selectedType === blogType.type
													? "outline outline-4 outline-primary outline-offset-2"
													: ""
											}`}
											onClick={() => {
												setSelectedType(blogType.type);
												field.onChange(blogType.type);
												setValue(
													"selectedIdea",
													isCustomIdea ? customIdea : selectedIdea.idea,
												);
											}}
										>
											{blogType.type === recommendedType && (
												<div className="absolute top-0 left-0 bg-primary text-primary-foreground text-xs font-semibold px-2 py-1 z-10 rounded-br-xl">
													{t(
														"content-creation.blog_post_from_multimedia.blog_type.recommended",
													)}
												</div>
											)}
											<CardHeader>
												<CardTitle className="flex items-center justify-between">
													{blogType.title}
													<div className="flex items-center space-x-2">
														{ideas.length > 1 && !isCustomIdea && (
															<>
																<Button
																	variant="outline"
																	size="sm"
																	onClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		const newIndex =
																			(selectedIndex - 1 + ideas.length) %
																			ideas.length;
																		setSelectedIdeaIndexes((prev) => ({
																			...prev,
																			[blogType.type]: newIndex,
																		}));
																		if (selectedType === blogType.type) {
																			setValue(
																				"selectedIdea",
																				ideas[newIndex].idea,
																			);
																		}
																	}}
																>
																	<ChevronLeft className="w-4 h-4" />
																</Button>
																<span className="text-sm">
																	{selectedIndex + 1} / {ideas.length}
																</span>
																<Button
																	variant="outline"
																	size="sm"
																	onClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		const newIndex =
																			(selectedIndex + 1) % ideas.length;
																		setSelectedIdeaIndexes((prev) => ({
																			...prev,
																			[blogType.type]: newIndex,
																		}));
																		if (selectedType === blogType.type) {
																			setValue(
																				"selectedIdea",
																				ideas[newIndex].idea,
																			);
																		}
																	}}
																>
																	<ChevronRight className="w-4 h-4" />
																</Button>
															</>
														)}
														<Button
															variant="outline"
															size="sm"
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																setIsCustomIdea(!isCustomIdea);
																if (selectedType === blogType.type) {
																	setValue(
																		"selectedIdea",
																		!isCustomIdea
																			? customIdea
																			: selectedIdea.idea,
																	);
																}
															}}
														>
															{isCustomIdea
																? t(
																		"content-creation.blog_post_from_multimedia.blog_type.use_recommended",
																	)
																: t(
																		"content-creation.blog_post_from_multimedia.blog_type.custom_idea",
																	)}
														</Button>
													</div>
												</CardTitle>
												<CardDescription>
													{isCustomIdea ? (
														<Input
															value={customIdea}
															onChange={(e) => {
																setCustomIdea(e.target.value);
																if (selectedType === blogType.type) {
																	setValue("selectedIdea", e.target.value);
																}
															}}
															onClick={(e) => e.stopPropagation()}
															placeholder={t(
																"content-creation.blog_post_from_multimedia.blog_type.add_custom_idea",
															)}
															className="mt-2"
														/>
													) : (
														selectedIdea && (
															<>
																<h4 className="font-semibold mt-2">
																	{selectedIdea.idea}
																</h4>
																<p className="text-sm mt-1">
																	{selectedIdea.content}
																</p>
															</>
														)
													)}
												</CardDescription>
											</CardHeader>
										</Card>
									);
								})}
							</div>
							{errors.selectedType && (
								<p className="mt-2 text-sm text-red-500">
									{errors.selectedType.message as string}
								</p>
							)}
						</FormItem>
					)}
				/>
				{contentTranscriptions.length > 0 && (
					<Collapsible className="mt-4">
						<CollapsibleTrigger className="flex items-center text-sm font-medium">
							<ChevronRight className="w-4 h-4 mr-2" />
							View Content Transcriptions
						</CollapsibleTrigger>
						<CollapsibleContent>
							{contentTranscriptions.map((ct) => (
								<Collapsible key={ct.name} className="mt-2">
									<CollapsibleTrigger className="flex items-center justify-between w-full p-2 text-sm font-medium bg-secondary rounded-md">
										<span>{ct.name}</span>
										<ChevronDown className="w-4 h-4" />
									</CollapsibleTrigger>
									<CollapsibleContent className="mt-2 p-2 bg-secondary/50 rounded-md">
										<div className="relative">
											<pre className="text-xs whitespace-pre-wrap">
												{expandedTranscriptions[ct.name]
													? ct.transcription
													: ct.transcription.length > 200
														? `${ct.transcription.slice(0, 200)}...`
														: ct.transcription}
											</pre>
											{ct.transcription.length > 200 && (
												<Button
													variant="link"
													size="sm"
													className="mt-2"
													type="button"
													onClick={() => toggleTranscription(ct.name)}
												>
													{expandedTranscriptions[ct.name]
														? "Show less"
														: "Show more"}
												</Button>
											)}
											<Button
												variant="ghost"
												size="sm"
												className="absolute top-0 right-0"
												onClick={() => copyToClipboard(ct.transcription)}
											>
												<Copy className="w-4 h-4" />
											</Button>
										</div>
									</CollapsibleContent>
								</Collapsible>
							))}
						</CollapsibleContent>
					</Collapsible>
				)}
			</CardContent>
		</Card>
	);
};

// Add this type definition at the top of the file
type AllFormData = z.infer<typeof Step1Schema> &
	z.infer<typeof Step2Schema> &
	z.infer<typeof Step3Schema> &
	z.infer<typeof Step4Schema> &
	z.infer<typeof Step6Schema>;

const steps: MultiFormStep<
	| typeof Step1Schema
	| typeof Step2Schema
	| typeof Step3Schema
	| typeof Step4Schema
	| typeof Step6Schema,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	any[]
>[] = [
	{
		id: "step_1",
		name: "Upload Multimedia",
		schema: Step1Schema,
		fn: async (data) => {
			try {
				const formData = new FormData();
				if ("files" in data && data.files) {
					for (const file of data.files) {
						formData.append("files", file);
					}
				}
				if ("text" in data && data.text) {
					formData.append("text", data.text);
				}
				if ("url" in data && data.url) {
					formData.append("url", data.url);
				}
				if ("youtubeUrl" in data && data.youtubeUrl) {
					formData.append("youtubeUrl", data.youtubeUrl);
				}
				if ("linkedinPosts" in data && data.linkedinPosts) {
					for (const post of data.linkedinPosts) {
						formData.append("linkedinPosts", post);
					}
				}
				let personalOpinion = "";
				if ("personalOpinion" in data && data.personalOpinion) {
					personalOpinion = data.personalOpinion;
					formData.append("personalOpinion", personalOpinion);
				}
				if ("domain" in data && data.domain) {
					formData.append("domain", data.domain.domain);
					formData.append("domainId", data.domain.id.toString());
				} else {
					throw new Error("Domain or domainId is required");
				}

				let language = "es";
				if ("language" in data && data.language) {
					language = data.language;
					formData.append("language", language);
				}

				const response = await axios.post(
					"/api/content-creation/multimedia/step1",
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					},
				);

				if (!response.data) {
					throw new Error("No data received from server");
				}

				return {
					recommendations: response.data.recommendations,
					combinedContent: response.data.combinedContent,
					contentTranscriptions: response.data.contentTranscriptions,
					...(personalOpinion && { personalOpinion }),
					language,
				};
			} catch (error) {
				if (axios.isAxiosError(error)) {
					throw new Error(
						error.response?.data?.message || "Error processing multimedia",
					);
				}
				throw error;
			}
		},
		component: ({ register, errors, control, t, setValue }) => {
			const [showEditor, setShowEditor] = useState(false);
			const [page, setPage] = useState(0);
			const [totalPosts, setTotalPosts] = useState<LinkedinPostType[]>([]);
			const [selectedPosts, setSelectedPosts] = useState<LinkedinPostType[]>(
				[],
			);
			const [selectedUser, setSelectedUser] = useState<
				WordPressUser | undefined
			>(undefined);
			const [selectedLanguage, setSelectedLanguage] = useState<"es" | "en">(
				"es",
			);
			const [wordpressConfig, setWordpressConfig] =
				useState<WordpressCredentialsState | null>(null);

			// biome-ignore lint/suspicious/noExplicitAny: <auth hook>
			const auth: any = useContext(AuthContext);
			const { toast } = useToast();
			const navigate = useNavigate();
			const queryClient = useQueryClient();

			const userId = auth?.user?.id;
			const organizationId = auth?.user?.organization_id;
			const count = 3;

			useEffect(() => {
				if (wordpressConfig) {
					if (
						wordpressConfig.domainId &&
						wordpressConfig.webUrl &&
						wordpressConfig.organizationId
					) {
						const domain = {
							id: wordpressConfig.domainId,
							domain: wordpressConfig.webUrl,
							organizationId: wordpressConfig.organizationId,
						};
						setValue("domain", domain);
					}

					if (wordpressConfig.user) {
						const wpUser = {
							id: wordpressConfig.user.id,
							username: wordpressConfig.user.username,
							name: wordpressConfig.user.name,
							userId: wordpressConfig.user.userId || null,
							email: wordpressConfig.user.email || null,
						};
						setSelectedUser(wpUser);
						setValue("wpUser", wpUser);
					}
				}
			}, [wordpressConfig, setValue]);

			const fetchLinkedinPostsFromDB = async ({
				userId,
				page,
				count,
			}: {
				userId: string;
				page: number;
				count: number;
			}) => {
				try {
					const response = await fetch(
						`${appConfig.API_MAIN_URL}/api/linkedin/posts?userId=${userId}&count=${count}&page=${page}`,
					);

					if (!response.ok) {
						throw new Error("Error fetching linkedin posts");
					}

					const posts: GetMorePostsFetchType = await response.json();
					return posts;
				} catch (error) {
					throw new Error("Error fetching linkedin posts");
				}
			};

			const { data, isError, isFetching, isPlaceholderData } = useQuery({
				queryKey: ["linkedinPosts", page, selectedUser?.userId],
				queryFn: () => {
					if (selectedUser?.userId) {
						return fetchLinkedinPostsFromDB({
							userId: selectedUser.userId,
							page,
							count,
						});
					}
					return Promise.resolve({ posts: [], hasMore: false });
				},
				enabled: !!selectedUser?.userId,
			});

			useEffect(() => {
				setTotalPosts([]);
				const invalidateQueries = async () => {
					if (selectedUser) {
						await queryClient.invalidateQueries({
							queryKey: ["linkedinPosts"],
						});
					}
				};
				invalidateQueries();
			}, [selectedUser, queryClient]);

			useEffect(() => {
				if (data?.posts) {
					setTotalPosts(data.posts);
				}
			}, [data?.posts]);

			const handlePostSelect = (post: LinkedinPostType) => {
				setSelectedPosts((prevSelected) => {
					const isAlreadySelected = prevSelected.some(
						(p) => p.linkedin_id === post.linkedin_id,
					);
					if (isAlreadySelected) {
						return prevSelected.filter(
							(p) => p.linkedin_id !== post.linkedin_id,
						);
					}
					return [...prevSelected, post];
				});
			};

			useEffect(() => {
				setValue(
					"linkedinPosts",
					selectedPosts.map((post) => post.content),
				);
			}, [selectedPosts, setValue]);

			useEffect(() => {
				setValue("language", selectedLanguage);
			}, [selectedLanguage, setValue]);

			return (
				<>
					<div className="mb-6">
						<LinkedinRefreshAlert userId={userId} t={t} />
						<WordPressCredentials
							userId={userId}
							organizationId={organizationId}
							setWordpressConfig={setWordpressConfig}
							wordpressConfig={wordpressConfig}
						/>
						{selectedUser && !selectedUser.userId && (
							<p className="mt-2 text-sm text-red-400">
								{t(
									"content-creation.blog_post_from_multimedia.multimedia_upload.no_user_id",
								)}
							</p>
						)}
					</div>
					<div className="mb-6">
						<label className="block text-sm font-medium mb-2">
							{t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.language",
							)}
						</label>
						<div className="flex gap-4">
							<button
								type="button"
								onClick={() => setSelectedLanguage("es")}
								className={`flex items-center gap-2 p-2 rounded-md border transition-all ${
									selectedLanguage === "es"
										? "border-primary bg-primary/10"
										: "border-gray-200 hover:border-primary/50"
								}`}
							>
								<ES className="w-8 h-8" />
								<span className="text-sm font-medium">
									{t(
										"content-creation.blog_post_from_multimedia.multimedia_upload.spanish",
									)}
								</span>
							</button>
							<button
								type="button"
								onClick={() => setSelectedLanguage("en")}
								className={`flex items-center gap-2 p-2 rounded-md border transition-all ${
									selectedLanguage === "en"
										? "border-primary bg-primary/10"
										: "border-gray-200 hover:border-primary/50"
								}`}
							>
								<US className="w-8 h-8" />
								<span className="text-sm font-medium">
									{t(
										"content-creation.blog_post_from_multimedia.multimedia_upload.english",
									)}
								</span>
							</button>
						</div>
					</div>
					<span className="block text-xs text-right opacity-50">
						{t(
							"content-creation.blog_post_from_multimedia.multimedia_upload.supported_file_types",
						)}
					</span>
					<FormField
						control={control}
						name="files"
						render={({ field }) => (
							<FormItem>
								<FileUploader
									value={field.value}
									onValueChange={field.onChange}
									maxFiles={4}
									maxSize={4 * 1024 * 1024}
									accept={{
										"text/plain": [".txt"],
										"application/pdf": [".pdf"],
										"audio/mpeg": [".mp3"],
										"audio/ogg": [".ogg"],
										"audio/x-m4a": [".m4a"],
									}}
								/>
								{"files" in errors && (
									<p className="col-span-full mt-2 text-sm text-red-400">
										{errors.files?.message}
									</p>
								)}
							</FormItem>
						)}
					/>
					<div className="mt-2 w-full">
						<Button
							type="button"
							variant={"link"}
							className="text-sm opacity-60"
							onClick={() => setShowEditor(!showEditor)}
						>
							{t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.add_text",
							)}
						</Button>
						{showEditor && (
							<Textarea
								{...register("text")}
								placeholder={t(
									"content-creation.blog_post_from_multimedia.multimedia_upload.type_or_paste_text",
								)}
								className="mt-2 w-full"
							/>
						)}
					</div>
					<Input
						{...register("url")}
						placeholder={t(
							"content-creation.blog_post_from_multimedia.multimedia_upload.enter_website_url",
						)}
						className="mt-2 w-full"
					/>
					{"url" in errors && (
						<p className="mt-2 text-sm text-red-400">{errors.url?.message}</p>
					)}
					<Input
						{...register("youtubeUrl")}
						placeholder={t(
							"content-creation.blog_post_from_multimedia.multimedia_upload.enter_youtube_url",
						)}
						className="mt-2 w-full"
					/>
					{"youtubeUrl" in errors && (
						<p className="mt-2 text-sm text-red-400">
							{errors.youtubeUrl?.message}
						</p>
					)}
					<div className="mt-4">
						<label className="text-sm font-medium">
							{t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.personal_opinion",
							)}
						</label>
						<Textarea
							{...register("personalOpinion")}
							placeholder={t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.add_personal_opinion",
							)}
							className="mt-2 w-full"
						/>
					</div>
					<div className="flex items-center justify-start">
						<h3 className="mt-4">
							{t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.latest_linkedin_posts",
							)}
						</h3>
						<LinkedinRefreshButton />
					</div>
					{totalPosts.length > 0 ? (
						<>
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
								{totalPosts.map((post) => (
									<LinkedinPost
										key={`${post.linkedin_id}`}
										post={post}
										isSelected={selectedPosts.some(
											(p) => p.linkedin_id === post.linkedin_id,
										)}
										onSelect={handlePostSelect}
									/>
								))}
							</div>

							<div className="flex justify-center mt-8">
								<Button
									variant="outline"
									className="bg-white"
									onClick={() => {
										if (!isPlaceholderData && data?.hasMore) {
											setPage((old) => old + 1);
										}
									}}
									disabled={isFetching || isPlaceholderData || !data?.hasMore}
								>
									{isFetching
										? t("content-creation.blog_post_from_multimedia.loading")
										: data?.hasMore
											? t(
													"content-creation.blog_post_from_multimedia.multimedia_upload.show_more",
												)
											: t(
													"content-creation.blog_post_from_multimedia.multimedia_upload.no_more_posts",
												)}
								</Button>
							</div>
						</>
					) : (
						!isFetching && (
							<p className="mt-4">
								{t(
									"content-creation.blog_post_from_multimedia.multimedia_upload.no_linkedin_posts_available",
								)}
							</p>
						)
					)}

					{isError && (
						<div className="mt-4 text-center text-red-500">
							{t(
								"content-creation.blog_post_from_multimedia.multimedia_upload.error_fetching_posts",
							)}
						</div>
					)}
				</>
			);
		},
	},
	{
		id: "step_2",
		name: "Blog Type",
		schema: Step2Schema,
		fn: async (data, allData) => {
			try {
				if (
					"selectedType" in data &&
					"combinedContent" in data &&
					"selectedIdea" in data
				) {
					const domain = allData[0]?.domain;
					const language = allData[0].language;
					const body = {
						selectedType: data.selectedType,
						combinedContent: data.combinedContent,
						selectedIdea: data.selectedIdea,
						domain: domain.domain,
						domainId: domain.id,
						language,
						...(data.personalOpinion && {
							personalOpinion: data.personalOpinion,
						}),
					};

					const response = await axios.post(
						"/api/content-creation/multimedia/step2",
						body,
					);

					if (!response.data) {
						throw new Error("No data received from server");
					}

					return { outlineData: response.data.outline };
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					throw new Error(
						error.response?.data?.message || "Error processing blog type",
					);
				}
				throw error;
			}
		},
		// @ts-ignore
		component: Step2,
	},
	{
		id: "step_3",
		name: "Blog Outline",
		schema: Step3Schema,
		fn: async (data, allData) => {
			try {
				if (
					"outline" in data &&
					"keywords" in data &&
					"combinedContent" in data &&
					"externalDomainPostsData" in data &&
					"internalDomainPostsData" in data &&
					"useEfficientModel" in data
				) {
					const language = allData[0].language;
					const domain = allData[0].domain;
					const body = {
						outline: data.outline,
						keywords: data.keywords,
						combinedContent: data.combinedContent,
						externalDomainPostsData: data.externalDomainPostsData,
						internalDomainPostsData: data.internalDomainPostsData,
						language,
						domain: domain.domain,
						personalOpinion: data.personalOpinion || undefined,
						useEfficientModel: data.useEfficientModel,
						...(allData[0].wpUser.userId && {
							userId: allData[0].wpUser.userId,
						}),
					};

					const response = await axios.post(
						"/api/content-creation/multimedia/step3",
						body,
					);

					if (!response.data) {
						throw new Error("No data received from server");
					}

					if (response.data.code === "GENERATION_IN_PROGRESS") {
						throw new Error("GENERATION_IN_PROGRESS");
					}

					return { htmlContent: response.data.htmlContent };
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response?.status === 429) {
						if (error.response.data?.code === "GENERATION_IN_PROGRESS") {
							throw new Error("GENERATION_IN_PROGRESS");
						}
					}
					throw new Error(
						error.response?.data?.message || "Error processing blog outline",
					);
				}
				throw error;
			}
		},
		component: ({ control, errors, metadata, setValue, t }) => {
			const { fields, append, remove, replace, move } = useFieldArray({
				control: control,
				name: "outline",
			});

			const outlineWatch = useWatch({
				control,
				name: "outline",
			});

			const outlineData = metadata[1]?.outlineData as OutlineData;
			const personalOpinion = metadata[0]?.personalOpinion as string;

			const {
				outline,
				keywords,
				combinedContent,
				externalDomainPostsData,
				internalDomainPostsData,
			} = outlineData;

			const [useEfficientModel, setUseEfficientModel] = useState(true);

			useEffect(() => {
				setValue("useEfficientModel", useEfficientModel);
			}, [useEfficientModel, setValue]);

			useEffect(() => {
				if (fields.length === 0 && outline && outline.length > 0) {
					replace(outline);
				}
				setValue("keywords", keywords);
				setValue("combinedContent", combinedContent);
				setValue("externalDomainPostsData", externalDomainPostsData);
				setValue("internalDomainPostsData", internalDomainPostsData);
				if (personalOpinion) {
					setValue("personalOpinion", personalOpinion);
				}
			}, [
				outline,
				keywords,
				combinedContent,
				externalDomainPostsData,
				internalDomainPostsData,
				fields.length,
				replace,
				setValue,
				personalOpinion,
			]);

			const getIndentation = (level: string) => {
				switch (level) {
					case "2":
						return "ml-0";
					case "3":
						return "ml-4";
					default:
						return "ml-0";
				}
			};

			return (
				<>
					<Card className="mx-auto w-full">
						<CardHeader>
							<CardTitle>
								{t(
									"content-creation.blog_post_from_multimedia.blog_outline.title",
								)}
							</CardTitle>
							<CardDescription>
								{t(
									"content-creation.blog_post_from_multimedia.blog_outline.description",
								)}
							</CardDescription>
							<div className="flex items-center mb-4">
								<div className="inline-flex items-center rounded-lg border bg-muted p-1">
									<button
										type="button"
										onClick={() => setUseEfficientModel(true)}
										className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors ${
											useEfficientModel
												? "bg-background text-foreground shadow-sm"
												: "text-muted-foreground hover:text-foreground"
										}`}
									>
										FAST
									</button>
									<button
										type="button"
										onClick={() => setUseEfficientModel(false)}
										className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors ${
											!useEfficientModel
												? "bg-background text-foreground shadow-sm"
												: "text-muted-foreground hover:text-foreground"
										}`}
									>
										QUALITY
									</button>
								</div>
								<p className="text-sm text-muted-foreground ml-4">
									{useEfficientModel
										? "Generates content faster but may be lower quality"
										: "Generates higher quality content but takes longer"}
								</p>
							</div>
						</CardHeader>
						<CardContent>
							<ul className="pl-0 ml-0">
								{fields.map((field, index) => {
									const outlineItem = outlineWatch?.[index]
										? outlineWatch[index]
										: field;
									return (
										<li
											key={field.id}
											className={`flex items-center space-x-1 ${getIndentation(outlineItem?.level || "1")}`}
										>
											<div className="flex flex-col space-y-1">
												<Button
													type="button"
													variant="ghost"
													size="sm"
													onClick={() => move(index, Math.max(0, index - 1))}
													disabled={index === 0}
													className="px-1 py-0 h-6"
												>
													<ChevronUp className="w-4 h-4" />
												</Button>
												<Button
													type="button"
													variant="ghost"
													size="sm"
													onClick={() =>
														move(index, Math.min(fields.length - 1, index + 1))
													}
													disabled={index === fields.length - 1}
													className="px-1 py-0 h-6"
												>
													<ChevronDown className="w-4 h-4" />
												</Button>
											</div>
											<FormField
												control={control}
												name={`outline.${index}.level`}
												render={({ field: levelField }) => (
													<Select
														onValueChange={(value) =>
															levelField.onChange(value)
														}
														value={levelField.value?.toString() || "1"}
													>
														<SelectTrigger icon={false} className="w-[60px]">
															<SelectValue placeholder="Level" />
														</SelectTrigger>
														<SelectContent>
															{[2, 3].map((level) => (
																<SelectItem
																	key={level}
																	value={level.toString()}
																>
																	H{level}
																</SelectItem>
															))}
														</SelectContent>
													</Select>
												)}
											/>
											<FormField
												control={control}
												name={`outline.${index}.title`}
												render={({ field: contentField }) => (
													<FormItem className="flex-grow">
														<Input {...contentField} className="w-full" />
													</FormItem>
												)}
											/>
											<Button
												type="button"
												variant="ghost"
												size="sm"
												onClick={() => remove(index)}
												className="px-2"
											>
												<Trash2 className="w-4 h-4 opacity-60" />
											</Button>
										</li>
									);
								})}
							</ul>
							<Button
								type="button"
								onClick={() =>
									append({
										title: "",
										level: "2",
									})
								}
								className="mt-4"
								variant="outline"
							>
								<Plus className="w-4 h-4 mr-2" /> Add Heading
							</Button>
							{"outline" in errors &&
								errors.outline &&
								"root" in errors.outline && (
									<p className="mt-2 text-sm text-red-500">
										{errors.outline.message}
									</p>
								)}

							<Accordion type="single" collapsible className="w-full mt-4">
								<AccordionItem value="keywords">
									<AccordionTrigger className="text-[1.1rem] font-semibold">
										Keywords
									</AccordionTrigger>
									<AccordionContent>
										<div className="space-y-2">
											{keywords.map((keyword, index) => (
												<div key={keyword}>
													<div className="rounded-md border px-4 py-3 font-mono text-sm">
														{keyword}
													</div>
												</div>
											))}
										</div>
										<Button
											type="button"
											onClick={() => setValue("keywords", [...keywords, ""])}
											className="mt-2"
											variant="outline"
										>
											<Plus className="w-4 h-4 mr-2" /> Add Keyword
										</Button>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CardContent>
					</Card>
				</>
			);
		},
	},
	{
		id: "step_4",
		name: "Review and Edit",
		schema: Step4Schema,
		fn: async (data, allData, captureEvent) => {
			try {
				if ("startTime" in data && data.startTime) {
					const endTime = Date.now();
					const timeToEdit = Math.round((endTime - data.startTime) / 1000);
					const modifiedBlog = data.content;
					const generatedBlog = data.generatedBlog;

					const domain = allData[0].domain;
					const wpUser = allData[0].wpUser;

					const blockContent = htmlToGutenbergBlocks(modifiedBlog);

					const strippedGeneratedBlog = stripHtml(generatedBlog);
					const strippedModifiedBlog = stripHtml(modifiedBlog);

					const changes = diffWords(
						strippedGeneratedBlog,
						strippedModifiedBlog,
					);

					const { wordsAdded, wordsDeleted } = changes.reduce(
						(acc, change) => {
							if (change.added) {
								acc.wordsAdded += change.count || 0;
							} else if (change.removed) {
								acc.wordsDeleted += change.count || 0;
							}
							return acc;
						},
						{ wordsAdded: 0, wordsDeleted: 0 },
					);

					const blogGenerationMetadata = {
						timeToEdit,
						wordsAdded,
						wordsDeleted,
						readabilityMetrics: data.readabilityMetrics,
					};

					if (captureEvent) {
						captureEvent("blog-edited", {
							wordsAdded,
							wordsDeleted,
							timeToEdit,
						});
					}

					const blogGenResponse = await axios.post(
						"/api/blog-post-generations/create-blog",
						{
							generatedBlog: strippedGeneratedBlog,
							modifiedBlog: strippedModifiedBlog,
							metadata: blogGenerationMetadata,
						},
					);

					if (!blogGenResponse.data) {
						throw new Error("Failed to create blog post generation");
					}

					const tryPublishWithCredentials = async (
						credentials: { applicationPassword: string; username: string }[],
						attemptIndex = 0,
					) => {
						if (attemptIndex >= 3 || attemptIndex >= credentials.length) {
							throw new Error(
								"Failed to publish after trying multiple WordPress credentials",
							);
						}

						try {
							const wpResponse = await axios.post(
								"/api/wordpress/publish-blogpost",
								{
									content: blockContent,
									title: allData[2].outline[0].title,
									excerpt: "",
									slug: allData[2].outline[0].title
										.toLowerCase()
										.replace(/\s+/g, "-"),
									domain: domain.domain,
									wordpress_application_password_key:
										credentials[attemptIndex].applicationPassword,
									wordpress_username: credentials[attemptIndex].username,
									status: "draft",
									wordpressUser: wpUser,
									creation_time: new Date().toISOString(),
									author: wpUser.id,
									date_gmt: new Date().toISOString(),
								},
							);

							if (!wpResponse.data?.id) {
								throw new Error("No post ID received from WordPress");
							}

							return wpResponse;
						} catch (error) {
							if (axios.isAxiosError(error) && error.response?.status === 401) {
								return tryPublishWithCredentials(credentials, attemptIndex + 1);
							}
							throw error;
						}
					};

					// Publish to WordPress
					const wpResponse = await tryPublishWithCredentials(
						data.wordpressCredentials,
					);

					if (!wpResponse.data?.id) {
						throw new Error("Failed to publish blog post");
					}

					const blogUrl = `https://${domain.domain}/wp-admin/post.php?post=${wpResponse.data.id}&action=edit`;
					window.open(blogUrl, "_blank");
					return { publishedUrl: blogUrl };
				}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					throw new Error(
						error.response?.data?.message || "Error processing review",
					);
				}
				throw error;
			}
		},
		component: ({
			metadata,
			register,
			setValue,
			control,
			captureEvent,
			previousValues,
		}) => {
			const htmlContent = metadata[2].htmlContent as string;
			const wpUser = previousValues[0].wpUser as WordPressUser;
			const domain = previousValues[0].domain as Domain;

			console.log("htmlContent", htmlContent);

			const useWordpressCredentialsData = async (
				domain: Domain,
			): Promise<WordPressCredentialsType[]> => {
				const response = await axios.get<WordPressCredentialsType[]>(
					`/api/wordpress/get/credentials/by-domain?domainId=${domain.id}`,
				);
				const domainAdminWordpressCredentials = response.data.filter(
					(credential) =>
						credential.domain_name === domain.domain &&
						credential.roles.includes("administrator"),
				);
				return domainAdminWordpressCredentials;
			};

			const {
				data: wordpressCredentials,
				isFetching: isFetchingWordpressCredentials,
				isError: isErrorWordpressCredentials,
			} = useQuery<WordPressCredentialsType[]>({
				queryKey: ["wordpressCredentials", domain.id],
				queryFn: () => {
					if (!domain.id) {
						throw new Error("Domain ID is required");
					}
					return useWordpressCredentialsData(domain);
				},
			});

			useEffect(() => {
				if (wordpressCredentials) {
					setValue(
						"wordpressCredentials",
						wordpressCredentials.map((credential) => ({
							applicationPassword: credential.application_password,
							username: credential.username,
						})),
					);
				}
			}, [wordpressCredentials, setValue]);

			useEffect(() => {
				if (htmlContent) {
					setValue("generatedBlog", htmlContent);
					setValue("startTime", Date.now());
				}
			}, [htmlContent, setValue]);

			return (
				<TextEditorWithReadability
					initialContent={htmlContent}
					onContentChange={(text) => setValue("content", text)}
					onReadabilityMetricsChange={(metrics) => {
						setValue("readabilityMetrics", metrics);
						if (captureEvent) {
							const eventData: Record<string, number> = {};
							for (const metric of metrics) {
								eventData[metric.name] = metric.value;
							}
							captureEvent("blog-generated", eventData);
						}
					}}
				/>
			);
		},
	},
	{
		id: "step_6",
		name: "Publish to WordPress",
		schema: Step6Schema,
		component: ({ metadata }) => {
			if (!metadata[3] || !metadata[3].publishedUrl) {
				return <div>Error: Blog post URL not available.</div>;
			}

			return (
				<div className="text-center show-blog-from-multimedia-survey">
					Visit and edit your post{" "}
					<a
						href={metadata[3].publishedUrl as string}
						target="_blank"
						className="text-blue-500 underline"
						rel="noreferrer"
					>
						here
					</a>
				</div>
			);
		},
	},
];

type Props = {
	t: (key: string) => string;
};

export const BlogFromMultimedia: React.FC<Props> = ({ t }) => {
	const { toast } = useToast();
	const { captureEvent } = useAnalytics();

	const handleSubmit = useCallback(
		async (
			data: z.infer<
				| typeof Step1Schema
				| typeof Step2Schema
				| typeof Step3Schema
				| typeof Step4Schema
				| typeof Step6Schema
			>[],
		) => {
			toast({
				title: "Success",
				description:
					"Blog post created successfully! Reload to create another blog.",
			});
		},
		[toast],
	);

	return (
		<div className="mx-auto max-w-5xl">
			<h1 className="mb-5 text-2xl font-bold">
				{t("content-creation.blog_post_from_multimedia.title")}
			</h1>
			<MultiStepForm
				name="blog-from-multimedia"
				t={t}
				steps={steps}
				onSubmit={handleSubmit}
				captureEvent={captureEvent}
			/>
		</div>
	);
};

export default BlogFromMultimedia;
