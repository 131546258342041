import type { MetricType } from "../types";
import { MetricPieChart } from "./metric-pie-chart";
import { SkeletonPieChart } from "./skeleton-pie-chart";

interface MetricPieChartsProps {
	metrics: MetricType[];
	loading: boolean;
}

export const MetricsPieCharts = ({
	metrics,
	loading,
}: MetricPieChartsProps) => {
	if (loading || (!loading && !metrics)) {
		return (
			<div className="flex items-center justify-center">
				<SkeletonPieChart />
				<SkeletonPieChart />
				<SkeletonPieChart />
				<SkeletonPieChart />
			</div>
		);
	}

	return (
		<div className="flex items-center justify-between w-full">
			<MetricPieChart
				metrics={metrics}
				metricKey="posts"
				title="Posts"
				description="Distribution of posts among contributors"
				key="pie-posts"
			/>
			<MetricPieChart
				metrics={metrics}
				metricKey="reach"
				title="Reach"
				description="Distribution of reach among contributors"
				key="pie-reach"
			/>
			<MetricPieChart
				metrics={metrics}
				metricKey="interactions"
				title="Interactions"
				description="Distribution of interactions among contributors"
				key="pie-interactions"
			/>
			<MetricPieChart
				metrics={metrics}
				metricKey="comments"
				title="Comments"
				description="Distribution of interactions among contributors"
				key="pie-comments"
			/>
		</div>
	);
};
