import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "components/ui/chart";
import { differenceInWeeks, parseISO, setWeek, startOfWeek } from "date-fns";
import * as React from "react";
import { Label, Pie, PieChart } from "recharts";

import type {
	GoalMetricType,
	GoalType,
	UserInitialGoalValueType,
	WeekProgressType,
} from "../types";
import {
	calculateCompletion,
	formatNumber,
	generateBlueHues,
	getCompletionFillColor,
} from "../utils";

import { useMemo } from "react";

interface MetricPieChartVsGoalsProps {
	weekProgressData: WeekProgressType[];
	userInitialGoalMetrics: UserInitialGoalValueType[];
	metricKey: keyof WeekProgressType;
	title: string;
	description: string;
	selectedWeek: number;
	goalMetrics: GoalMetricType[];
	goal: GoalType;
	selectedTeamId: string | null;
}

const getAccumulatedData = (
	weekProgressData: WeekProgressType[],
	userInitialGoalMetrics: UserInitialGoalValueType[],
	metricKey: keyof WeekProgressType,
	selectedWeek: number,
	selectedTeamId: string | null,
	goalMetrics: GoalMetricType[],
	goal: GoalType,
) => {
	const mostRecentWeekData = weekProgressData[weekProgressData.length - 1];

	const metricArray = mostRecentWeekData[metricKey];
	let metricData: { [key: string]: { user_name: string; value: number } } = {};

	const userStartDates = userInitialGoalMetrics.reduce(
		(acc, { metricType, data }) => {
			if (metricType === metricKey) {
				for (const { user_id, start_date } of data) {
					acc[user_id] = parseISO(start_date.split("T")[0]);
				}
			}
			return acc;
		},
		{} as { [key: string]: Date },
	);

	const currentYear = new Date().getFullYear();
	const selectedWeekDate = setWeek(new Date(currentYear, 0, 1), selectedWeek, {
		weekStartsOn: 1,
	});

	if (Array.isArray(metricArray)) {
		metricData = metricArray.reduce(
			(acc, { user_id, user_name, value }) => {
				const startDate = userStartDates[user_id];
				if (startDate) {
					const weeksPassed = differenceInWeeks(
						startOfWeek(selectedWeekDate, { weekStartsOn: 1 }),
						startOfWeek(startDate, { weekStartsOn: 1 }),
					);

					if (weeksPassed >= 0) {
						if (!acc[user_id]) {
							acc[user_id] = { user_name, value };
						} else {
							acc[user_id].value += value;
						}
					}
				}
				return acc;
			},
			{} as { [key: string]: { user_name: string; value: number } },
		);
	}

	const hues = generateBlueHues(Object.keys(metricData).length);

	const accumulatedData = Object.entries(metricData)
		.map(([, { user_name, value }], index) => ({
			name: user_name,
			value,
			fill: `hsl(${hues[index]}, 70%, 50%)`,
		}))
		.sort((a, b) => b.value - a.value);

	const objectives = userInitialGoalMetrics.reduce(
		(acc, { metricType, weeklyGrowthRate, data }) => {
			if (metricType === metricKey) {
				if (!selectedTeamId) {
					const goalMetric = goalMetrics.find(
						(gm) => gm.metric_type === metricKey,
					);
					if (goalMetric) {
						const weeksPassed = differenceInWeeks(
							startOfWeek(selectedWeekDate, { weekStartsOn: 1 }),
							startOfWeek(parseISO(goal.start_date.split("T")[0]), {
								weekStartsOn: 1,
							}),
						);
						const expectedValue = Math.round(
							goalMetric.initial_value * weeklyGrowthRate ** weeksPassed,
						);
						acc.total = {
							initial_value: goalMetric.initial_value,
							expectedValue,
						};
					}
				} else {
					for (const { user_id, initial_value, start_date } of data) {
						const startDate = parseISO(start_date.split("T")[0]);
						const weeksPassed = differenceInWeeks(
							startOfWeek(selectedWeekDate, { weekStartsOn: 1 }),
							startOfWeek(startDate, { weekStartsOn: 1 }),
						);

						if (weeksPassed >= 0) {
							const expectedValue = Math.round(
								initial_value * weeklyGrowthRate ** weeksPassed,
							);
							if (!acc[user_id]) {
								acc[user_id] = { initial_value, expectedValue };
							} else {
								acc[user_id].expectedValue += expectedValue;
							}
						}
					}
				}
			}
			return acc;
		},
		{} as { [key: string]: { initial_value: number; expectedValue: number } },
	);

	return { accumulatedData, objectives };
};

const generateChartConfig = (title: string) => ({
	[title.toLowerCase()]: {
		label: title,
	},
});

export const MetricPieChartVsGoals = ({
	weekProgressData,
	userInitialGoalMetrics,
	metricKey,
	title,
	description,
	selectedWeek,
	goalMetrics,
	selectedTeamId,
	goal,
}: MetricPieChartVsGoalsProps) => {
	const filteredWeekProgressData = useMemo(() => {
		return weekProgressData.filter((weekData) => weekData.week <= selectedWeek);
	}, [weekProgressData, selectedWeek]);

	const { accumulatedData, objectives } = useMemo(
		() =>
			getAccumulatedData(
				filteredWeekProgressData,
				userInitialGoalMetrics,
				metricKey,
				selectedWeek,
				selectedTeamId,
				goalMetrics,
				goal,
			),
		[
			filteredWeekProgressData,
			userInitialGoalMetrics,
			metricKey,
			selectedWeek,
			selectedTeamId,
			goalMetrics,
			goal,
		],
	);

	const total = React.useMemo(() => {
		return accumulatedData.reduce((acc, curr) => acc + curr.value, 0);
	}, [accumulatedData]);

	const totalObjective = React.useMemo(() => {
		return Object.values(objectives).reduce(
			(acc, { expectedValue }) => acc + expectedValue,
			0,
		);
	}, [objectives]);

	const completion = Math.round(calculateCompletion(total, totalObjective));

	const chartConfig = React.useMemo(() => generateChartConfig(title), [title]);

	return (
		<Card className="flex flex-col bg-white w-72 h-[330px]">
			<CardHeader className="pb-0">
				<CardTitle className="text-2xl">{title}</CardTitle>
				<CardDescription>
					Current objective for this week is: {""}
					<span className="font-bold">{formatNumber(totalObjective)}</span>
				</CardDescription>
			</CardHeader>
			<CardContent className="flex-1 py-2">
				<ChartContainer config={chartConfig} className="mx-auto aspect-square">
					<PieChart>
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent hideLabel />}
						/>
						<Pie
							data={accumulatedData}
							dataKey="value"
							nameKey="name"
							innerRadius={60}
							strokeWidth={5}
							startAngle={90}
							endAngle={-270}
							paddingAngle={2}
							animationBegin={250}
						>
							<Label
								content={({ viewBox }) => {
									if (
										viewBox &&
										"cx" in viewBox &&
										"cy" in viewBox &&
										viewBox.cx &&
										viewBox.cy
									) {
										return (
											<>
												<text
													x={viewBox.cx}
													y={viewBox.cy - 9}
													textAnchor="middle"
													dominantBaseline="middle"
													className="fill-foreground text-3xl font-bold"
												>
													{formatNumber(total)}
												</text>
												<text
													x={viewBox.cx}
													y={viewBox.cy + 13}
													textAnchor="middle"
													dominantBaseline="middle"
													className={`text-lg font-bold ${getCompletionFillColor(completion)}`}
												>
													(<tspan className="">{completion}</tspan>
													%)
												</text>
												<text
													x={viewBox.cx}
													y={viewBox.cy + 25}
													textAnchor="middle"
													dominantBaseline="middle"
													className="text-md"
												>
													<tspan className="">completion</tspan>
												</text>
											</>
										);
									}
								}}
							/>
						</Pie>
					</PieChart>
				</ChartContainer>
			</CardContent>
		</Card>
	);
};
