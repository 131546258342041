/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { useState } from "react";
import { appConfig } from "views/content-creation/config";

import { type WordPressUser, WordPressUsers } from "./wordpress-users";

export type WordPressCredentialsType = {
	id: number;
	user_id: string;
	organization_id: string;
	domain: string;
	domain_id: number;
	username: string;
	application_password: string;
	shared_with_organization: boolean;
	createdAt: string;
	updatedAt: string;
	roles: string[];
};

const fetchWordPressCredentials = async (
	userId: string,
	organizationId: string,
): Promise<WordPressCredentialsType[]> => {
	const response = await fetch(
		`${appConfig.API_MAIN_URL}/api/wordpress/get/credentials?userId=${userId}&organizationId=${organizationId}`,
	);
	if (!response.ok) {
		throw new Error("Error fetching WordPress credentials");
	}
	const credentials = await response.json();

	return credentials;
};

const addWordPressCredentials = async (
	credentials: Omit<
		WordPressCredentialsType,
		"id" | "createdAt" | "updatedAt" | "roles" | "domain_id"
	>,
	userId: string,
	organizationId: string,
): Promise<WordPressCredentialsType> => {
	const response = await fetch(
		`${appConfig.API_MAIN_URL}/api/wordpress/store-credentials`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				credentials: {
					domain: credentials.domain,
					wordpress_username: credentials.username,
					wordpress_application_password_key: credentials.application_password,
				},
				userId,
				organizationId,
				sharedWithOrganization: credentials.shared_with_organization === true,
			}),
		},
	);
	if (!response.ok) {
		throw new Error(
			"Wrong credentials. Check this tutorial for application passwords: https://www.nextsoftwaresolutions.com/kb/create-application-passwords-in-wordpress/",
		);
	}
	return response.json() as any;
};

export type WordpressCredentialsState = {
	applicationPassword?: string;
	webUrl?: string;
	username?: string;
	user?: WordPressUser;
	domainId?: number;
	organizationId?: string;
};

type WordPressCredentialsProps = {
	userId: string;
	organizationId: string;
	wordpressConfig: WordpressCredentialsState | null;
	setWordpressConfig: React.Dispatch<
		React.SetStateAction<WordpressCredentialsState | null>
	>;
};

export const WordPressCredentials: React.FC<WordPressCredentialsProps> = ({
	userId,
	organizationId,
	setWordpressConfig,
	wordpressConfig,
}) => {
	const queryClient = useQueryClient();
	const {
		data: credentials,
		isLoading,
		isError,
	} = useQuery({
		queryKey: ["wordPressCredentials", userId, organizationId],
		queryFn: () => fetchWordPressCredentials(userId, organizationId),
	});

	const addCredentialsMutation = useMutation({
		mutationFn: (
			credentials: Omit<
				WordPressCredentialsType,
				"id" | "createdAt" | "updatedAt" | "roles" | "domain_id"
			>,
		) => addWordPressCredentials(credentials, userId, organizationId),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: ["wordPressCredentials", userId, organizationId],
			});
			setIsDialogOpen(false);
		},
		onError: (error: Error) => {
			setErrorMessage(error.message);
		},
	});

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [domain, setDomain] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [sharedWithOrganization, setSharedWithOrganization] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const handleAddCredentials = () => {
		setErrorMessage(null); // Reset error message
		addCredentialsMutation.mutate({
			user_id: userId,
			application_password: password,
			domain,
			username,
			organization_id: organizationId,
			shared_with_organization: sharedWithOrganization,
		});
	};

	const handleSelectChange = (value: string) => {
		const credential = credentials?.find(
			(c) => c.domain === value && c.roles.includes("administrator"),
		);
		if (credential) {
			setWordpressConfig({
				username: credential.username,
				applicationPassword: credential.application_password,
				webUrl: credential.domain,
				domainId: credential.domain_id,
				organizationId: credential.organization_id,
			});
		}
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (isError) {
		return <div>Error fetching WordPress credentials</div>;
	}

	const uniqueAdminCredentials = credentials?.reduce((acc, credential) => {
		if (
			credential.roles.includes("administrator") &&
			!acc.some((c) => c.domain === credential.domain)
		) {
			acc.push(credential);
		}
		return acc;
	}, [] as WordPressCredentialsType[]);

	return (
		<>
			<h2 className="text-sm font-medium text-muted-foreground">
				Wordpress Config
			</h2>
			<div className="flex flex-col gap-3 w-full sm:flex-row">
				<Select onValueChange={handleSelectChange}>
					<SelectTrigger className="w-[280px]">
						<SelectValue placeholder="Select WordPress Domain" />
					</SelectTrigger>
					<SelectContent>
						{uniqueAdminCredentials?.map((credential) => (
							<SelectItem key={credential.domain} value={credential.domain}>
								{credential.domain}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
				<WordPressUsers
					credentials={wordpressConfig}
					setWordPressUser={(user) => {
						setWordpressConfig((prev) => ({
							...prev,
							user,
						}));
					}}
				/>
				<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
					<DialogTrigger asChild>
						<Button>Add New Credentials</Button>
					</DialogTrigger>
					<DialogContent className="sm:max-w-[425px]">
						<DialogHeader>
							<DialogTitle>Add WordPress Credentials</DialogTitle>
							<DialogDescription>
								Enter your WordPress credentials to add a new connection.
							</DialogDescription>
						</DialogHeader>
						<div className="grid gap-4 py-4">
							{errorMessage && (
								<div className="text-red-500">{errorMessage}</div>
							)}
							<div className="grid grid-cols-4 gap-4 items-center">
								<Label htmlFor="domain" className="text-right">
									Domain
								</Label>
								<Input
									id="domain"
									value={domain}
									onChange={(e) => setDomain(e.target.value)}
									className="col-span-3"
								/>
							</div>
							<div className="grid grid-cols-4 gap-4 items-center">
								<Label htmlFor="username" className="text-right">
									Username
								</Label>
								<Input
									id="username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									className="col-span-3"
								/>
							</div>
							<div className="grid grid-cols-4 gap-4 items-center">
								<Label htmlFor="password" className="text-right">
									Application Password
								</Label>
								<Input
									id="password"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									className="col-span-3"
								/>
							</div>
							<div className="grid grid-cols-4 gap-4 items-center">
								<Label htmlFor="shared" className="text-right">
									Share with organization
								</Label>
								<input
									type="checkbox"
									id="shared"
									checked={sharedWithOrganization}
									onChange={(e) => setSharedWithOrganization(e.target.checked)}
									className="mr-auto"
								/>
							</div>
						</div>
						<DialogFooter>
							<Button type="submit" onClick={handleAddCredentials}>
								Add Credentials
							</Button>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</div>
		</>
	);
};
