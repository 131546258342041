/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

"use client";

import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "components/ui/chart";
import { MoveRight, TrendingDown, TrendingUp } from "lucide-react";
import * as React from "react";
import { Label, Pie, PieChart } from "recharts";

import type { MetricType } from "../types";
import { calculateTrend, formatNumber, generateBlueHues } from "../utils";

interface MetricPieChartProps {
	metrics: MetricType[];
	metricKey: keyof MetricType;
	title: string;
	description: string;
}

const getMetricData = (metrics: MetricType[], key: keyof MetricType) => {
	const hues = generateBlueHues(metrics.length);

	// Sort metrics in descending order based on the metric value
	const sortedMetrics = [...metrics].sort(
		(a, b) => (b[key] as number) - (a[key] as number),
	);

	return sortedMetrics.map(
		(metric: { [x: string]: any; user_name: string }, index) => ({
			name: metric.user_name,
			value: metric[key],
			fill: `hsl(${hues[index]}, 70%, 50%)`, // Random color for each slice
		}),
	);
};

const generateChartConfig = (title: string) => ({
	[title.toLowerCase()]: {
		label: title,
	},
});

export const MetricPieChart = ({
	metrics,
	metricKey,
	title,
	description,
}: MetricPieChartProps) => {
	const chartData = React.useMemo(
		() => getMetricData(metrics, metricKey),
		[metrics, metricKey],
	);

	const total = React.useMemo(() => {
		return chartData.reduce((acc, curr) => acc + curr.value, 0);
	}, [chartData]);

	const previousTotal = React.useMemo(() => {
		return metrics.reduce((acc, metric) => {
			const previousValueKey: string = `previous${metricKey.charAt(0).toUpperCase()}${metricKey.slice(1)}`;
			const previousValue = metric[
				previousValueKey as keyof MetricType
			] as number;
			return acc + previousValue;
		}, 0);
	}, [metrics, metricKey]);

	const trend = Math.round(calculateTrend(total, previousTotal));
	const trendColor = trend > 0 ? "green" : trend < 0 ? "red" : "gray";
	const trendIcon =
		trend > 0 ? (
			<TrendingUp className="h-4 w-4" />
		) : trend < 0 ? (
			<TrendingDown className="h-4 w-4" />
		) : (
			<MoveRight className="h-4 w-4" />
		);

	const chartConfig = React.useMemo(() => generateChartConfig(title), [title]);

	return (
		<Card className="flex flex-col bg-white h-[330px]">
			<CardHeader className="pb-0">
				<CardTitle className="text-2xl">{title}</CardTitle>
				<CardDescription>
					<div className="flex items-center gap-2 font-medium leading-none whitespace-nowrap">
						{trend !== 0 ? (
							<>
								<span>Trending {trend > 0 ? "up" : "down"} by</span>
								<span
									className={`font-bold ${trend > 0 ? "text-green-500" : "text-red-500"}`}
								>
									{trend}%
								</span>
								<span>this period</span>
								<span>{trendIcon}</span>
							</>
						) : (
							<>No significant change during this period {trendIcon}</>
						)}
					</div>
				</CardDescription>
			</CardHeader>
			<CardContent className="flex-1 py-2">
				<ChartContainer config={chartConfig} className="mx-auto aspect-square">
					<PieChart>
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent hideLabel />}
						/>
						<Pie
							data={chartData}
							dataKey="value"
							nameKey="name"
							innerRadius={60}
							// outerRadius={90}
							strokeWidth={5}
							startAngle={90}
							endAngle={-270}
							paddingAngle={2}
							animationBegin={250}
						>
							<Label
								content={({ viewBox }) => {
									if (viewBox && "cx" in viewBox && "cy" in viewBox) {
										return (
											<text
												x={viewBox.cx}
												y={viewBox.cy}
												textAnchor="middle"
												dominantBaseline="middle"
											>
												<tspan
													x={viewBox.cx}
													y={viewBox.cy}
													className="fill-foreground text-3xl font-bold"
												>
													{formatNumber(total)}
												</tspan>
												<tspan
													x={viewBox.cx}
													y={(viewBox.cy || 0) + 24}
													className="fill-muted-foreground"
												>
													{title}
												</tspan>
											</text>
										);
									}
								}}
							/>
						</Pie>
					</PieChart>
				</ChartContainer>
			</CardContent>
		</Card>
	);
};
