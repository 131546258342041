/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and user)
 *
 *   PROPS
 *   children: will be passed from router > view > here (component(s), required)
 *   title: title of the view for the header (string, required)
 *
 **********/

import {
	AppNav,
	AuthContext,
	Header,
	User,
	useNavigate,
	usePermissions,
	useTranslation,
} from "components/lib";
import { Fragment, useContext } from "react";

import Style from "./app.tailwind.js";

export function AppLayout(props) {
	// context & style
	const { t } = useTranslation();
	const context = useContext(AuthContext);
	const navigate = useNavigate();

	const squadsOrganizationId = "3830378c-54a8-49b7-a161-e0c7b0b9a6f7";
	const emblueOrganizationId = "be332384-de90-4ae9-a588-1d5ed8f2cfee";
  const mentalTestLabOrganizationId = "fe382e7f-3ec4-4f17-9418-b4cf09b5335d";

	const canSeeContentCreation =
		context.user?.organization_id === squadsOrganizationId ||
		context.user?.organization_id === emblueOrganizationId ||
		context.user?.organization_id === mentalTestLabOrganizationId;

	const isOwnerOrAdmin =
		context.user?.permission === "owner" ||
		context.user?.permission === "admin";

	return (
		<Fragment>
			<AppNav
				items={[
					{ label: t("nav.dashboard"), icon: "activity", link: "/dashboard" },
					{
						label: t("nav.my_statistics"),
						icon: "bar-chart-2",
						link: "/stats",
					},
					canSeeContentCreation && {
						label: t("nav.content_generation"),
						icon: "file-text",
						link: "/dashboard/content-creation",
					},
					isOwnerOrAdmin && {
						label: t("nav.teams"),
						icon: "users",
						link: "/teams",
					},
					isOwnerOrAdmin && {
						label: t("nav.organization_goals"),
						icon: "target",
						link: "/organization-goals",
					},
					process.env.NODE_ENV === "development" && {
						label: t("nav.growth_funnel"),
						icon: "align-center",
						link: "/growth-funnel",
					},
					{ label: t("nav.organization"), icon: "user", link: "/organization" },
					{ label: t("nav.help"), icon: "help-circle", link: "/help" },
					{
						label: t("nav.signout"),
						icon: "log-out",
						action: async () => {
							const urlToSignin = await context.signout();
							console.log("url to signin", urlToSignin);
							navigate(urlToSignin);
						},
					},
				].filter(Boolean)}
			/>

			<main className={Style.app}>
				<Header title={t(props.title)}>
					<User />
				</Header>

				{props.children}
			</main>
		</Fragment>
	);
}
