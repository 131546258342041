/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AuthContext } from "components/lib";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import { useContext } from "react";
import { NavLink } from "react-router-dom";

import { tools } from "./tools";
import type { Tool } from "./types";

export const ContentGeneration = ({ t }: { t: (key: string) => string }) => {
	const auth: any = useContext(AuthContext);

	if (!auth || !auth.user) {
		return null;
	}

	return (
		<div className="mx-auto max-w-7xl">
			<h1 className="mb-5 text-2xl font-bold">
				{t("content-creation.main.tools-title")}
			</h1>
			<ToolsSection
				tools={tools}
				userId={auth.user.id}
				organizationId={auth.user.organization_id}
			/>
		</div>
	);
};

function ToolsSection({
	tools,
	userId,
	organizationId,
}: {
	tools: Tool[];
	userId: string;
	organizationId: string;
}) {
	return (
		<div className="flex flex-col lg:flex-row">
			<section className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:pr-4">
				{tools
					.filter((tool) => tool.released)
					.filter((tool) =>
						tool.internal
							? organizationId === "3830378c-54a8-49b7-a161-e0c7b0b9a6f7"
							: true,
					)
					.map((tool) => (
						<NavLink
							key={tool.href}
							to={tool.href}
							className="flex h-full max-h-96"
						>
							<Card className="transition-colors hover:bg-card-hover">
								<CardHeader>
									<CardTitle>{tool.name}</CardTitle>
									<CardDescription>{tool.shortDescription}</CardDescription>
								</CardHeader>
								<CardContent>{tool.longDescription}</CardContent>
							</Card>
						</NavLink>
					))}
				<Card className="max-h-96 border-dashed">
					<CardHeader>
						<CardTitle>More tools coming soon</CardTitle>
						<CardDescription>
							We are working on more tools to help you create content faster.
						</CardDescription>
					</CardHeader>
				</Card>
			</section>
		</div>
	);
}
