import { useAPI } from "components/lib";

export interface User {
	user_name: string;
	user_id: string;
	posts: number;
	previousPosts: number;
	postsChange: number;
	reach: number;
	previousReach: number;
	reachChange: number;
	interactions: number;
	previousInteractions: number;
	interactionsChange: number;
	comments: number;
	previousComments: number;
	commentsChange: number;
	shares: number;
	previousShares: number;
	sharesChange: number;
	followers: number;
	followersChange: number;
	engagement: number;
	previousEngagement: number;
	engagementChange: number;
	blogs: number;
	previousBlogs: number;
	blogsChange: number;
}

interface UseOrganizationUsersReturn {
	users: User[] | null;
	loading: boolean;
	error: Error | null;
}

export const useOrganizationUsers = (
	organizationId: string | undefined,
	startDate: string,
	endDate: string,
	timeFrameType = "days",
): UseOrganizationUsersReturn => {
	const {
		data: users,
		loading,
		error,
	} = useAPI(
		organizationId
			? `/api/analytics/${organizationId}/collaborators?startDate=${startDate}&endDate=${endDate}&type=${timeFrameType}`
			: null,
	);

	return {
		users,
		loading,
		error,
	};
};
