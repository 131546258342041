import { cn } from "lib/utils";

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
	className?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({ className, ...props }) => {
	return (
		<div
			className={cn("animate-pulse rounded-md bg-primary/10", className)}
			{...props}
		/>
	);
};

export { Skeleton };
