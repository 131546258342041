import { useMutation, useQuery } from "@tanstack/react-query";
import { Button } from "components/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "components/ui/select";
import { useToast } from "components/lib";
import { useState } from "react";
import { appConfig } from "views/content-creation/config";
import type { WordPressUser } from "./wordpress-users";
import { Card } from "components/lib";

type WordPressUserSelectorProps = {
    userId: string;
    organizationId: string;
    onUserSelected?: (user: WordPressUser) => void;
};

type Credential = {
    domain: string;
    username: string;
    application_password: string;
    roles: string[];
};

export const WordPressUserSelector = ({ 
    userId, 
    organizationId,
    onUserSelected 
}: WordPressUserSelectorProps) => {
    const { toast } = useToast();
    const [selectedDomain, setSelectedDomain] = useState<string>("");
    const [selectedUser, setSelectedUser] = useState<WordPressUser | null>(null);

    // Fetch user credentials (domains)
    const { data: credentials, isLoading: isLoadingCredentials } = useQuery({
        queryKey: ["userWordPressCredentials", userId, organizationId],
        queryFn: async () => {
            try {
                const response = await fetch(
                    `${appConfig.API_MAIN_URL}/api/wordpress/get/credentials?userId=${userId}&organizationId=${organizationId}`
                );
                if (!response.ok) {
                    throw new Error("Error fetching WordPress credentials");
                }
                const data = await response.json();
                
                const uniqueDomains = data.reduce((acc: Credential[], current: Credential) => {
                    const exists = acc.find(item => item.domain === current.domain);
                    if (!exists) {
                        acc.push(current);
                    }
                    return acc;
                }, []);

                return uniqueDomains;
            } catch (error) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "No se pudieron cargar las credenciales de WordPress"
                });
                throw error;
            }
        }
    });

    const { data: wpUsers, isLoading: isLoadingUsers } = useQuery({
        queryKey: ["wordPressUsers", selectedDomain],
        enabled: !!selectedDomain,
        queryFn: async () => {
            try {
                const credential = credentials?.find((c: { domain: string; }) => c.domain === selectedDomain);
                const response = await fetch(`${appConfig.API_MAIN_URL}/api/wordpress/get/users`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        domain: credential.domain,
                        wordpress_username: credential.username,
                        wordpress_application_password_key: credential.application_password,
                    }),
                });
                if (!response.ok) {
                    throw new Error("Error fetching WordPress users");
                }
                return response.json();
            } catch (error) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "No se pudieron cargar los usuarios de WordPress"
                });
                throw error;
            }
        }
    });

    const linkUserMutation = useMutation({
        mutationFn: async () => {
            if (!selectedUser) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "Por favor selecciona un usuario primero"
                });
                throw new Error("No user selected");
            }
            
            try {
                const response = await fetch(`${appConfig.API_MAIN_URL}/api/wordpress/link-user`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userId,
                        wpUserId: selectedUser.id
                    }),
                });
                
                if (!response.ok) {
                    throw new Error("Error linking WordPress user");
                }
                return response.json();
            } catch (error) {
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "No se pudo vincular el usuario de WordPress"
                });
                throw error;
            }
        },
        onSuccess: () => {
            if (selectedUser && onUserSelected) {
                toast({
                    title: "¡Éxito!",
                    description: "Usuario de WordPress vinculado correctamente"
                });
                onUserSelected(selectedUser);
            }
        }
    });

    const handleDomainChange = (value: string) => {
        setSelectedDomain(value);
        setSelectedUser(null);
    };

    const handleUserChange = (value: string) => {
        const user = wpUsers?.find((u: WordPressUser) => u.id?.toString() === value);
        setSelectedUser(user || null);
    };

    const handleLinkUser = () => {
        if (selectedUser) {
            linkUserMutation.mutate();
        }
    };

    const isLoading = isLoadingCredentials || isLoadingUsers || linkUserMutation.isPending;

    return (
        <Card loading={isLoading}>
            {!isLoading && (
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <label htmlFor="wp-domain" className="text-sm font-medium text-muted-foreground">
                            WordPress Domain
                        </label>
                        <Select onValueChange={handleDomainChange} value={selectedDomain}>
                            <SelectTrigger className="w-[280px]">
                                <SelectValue placeholder="Select WordPress Domain" />
                            </SelectTrigger>
                            <SelectContent>
                                {credentials?.map((credential: Credential) => (
                                    <SelectItem key={credential.domain} value={credential.domain}>
                                        {credential.domain}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    {selectedDomain && (
                        <div className="flex flex-col gap-2">
                            <label htmlFor="wp-user" className="text-sm font-medium text-muted-foreground">
                                WordPress User
                            </label>
                            <Select 
                                onValueChange={handleUserChange} 
                                value={selectedUser?.id?.toString()}
                                disabled={isLoadingUsers}
                            >
                                <SelectTrigger className="w-[280px]">
                                    <SelectValue placeholder="Select WordPress User" />
                                </SelectTrigger>
                                <SelectContent>
                                    {wpUsers?.map((user: WordPressUser) => (
                                        <SelectItem key={user.id} value={user.id?.toString() || ""}>
                                            {user.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    )}

                    {selectedUser && (
                        <Button 
                            onClick={handleLinkUser}
                            disabled={linkUserMutation.isPending}
                        >
                            {linkUserMutation.isPending ? "Linking..." : "Link User"}
                        </Button>
                    )}
                </div>
            )}
        </Card>
    );
}; 