import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import { differenceInCalendarWeeks } from "date-fns";
import { differenceInWeeks, parseISO, setWeek, startOfWeek } from "date-fns";
import type React from "react";
import { useEffect, useState } from "react";
import {
	Bar,
	CartesianGrid,
	ComposedChart,
	Legend,
	Line,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import type {
	GoalMetricType,
	GoalType,
	MetricKeys,
	UserInitialGoalValueType,
	WeekProgressType,
} from "../types";

interface MetricsChartVsGoalProps {
	organizationId: string;
	t: (key: string) => string;
	metric: MetricKeys;
	backgroundColor: string;
	borderColor: string;
	weeklyProgressData: WeekProgressType[];
	goalMetrics: GoalMetricType[];
	goal: GoalType;
	userInitialGoalMetrics: UserInitialGoalValueType[];
}

const calculateWeeklyObjectives = (
	initialValue: number,
	weeklyGrowthRate: number,
	weeks: number,
) => {
	const objectives = [];
	for (let week = 0; week <= weeks; week++) {
		objectives.push(initialValue * weeklyGrowthRate ** week);
	}
	return objectives;
};

const MetricsChartVsGoal: React.FC<MetricsChartVsGoalProps> = ({
	organizationId,
	t,
	metric,
	backgroundColor,
	borderColor,
	weeklyProgressData,
	goalMetrics,
	goal,
	userInitialGoalMetrics,
}) => {
	const [data, setData] = useState<any[]>([]);

	useEffect(() => {
		if (goal && goalMetrics?.length && weeklyProgressData?.length) {
			const metricGoal = goalMetrics.find(
				(goalMetric) => goalMetric.metric_type === metric,
			);

			if (!metricGoal) return;

			const totalWeeks = differenceInWeeks(goal.end_date, goal.start_date);

			const weeklyObjectives = calculateWeeklyObjectives(
				metricGoal.initial_value,
				metricGoal.weekly_growth_rate,
				totalWeeks,
			);

			const firstWeekNumber = weeklyProgressData[0].week;

			const userInitialGoalMetric = userInitialGoalMetrics.find(
				(ugm) => ugm.metricType === metric,
			);

			if (!userInitialGoalMetric) return;

			const currentYear = new Date().getFullYear();

			const combinedData = Array.from(
				{ length: totalWeeks + 1 },
				(_, index) => {
					const weekNumber = firstWeekNumber + index;
					const weekData = weeklyProgressData.find(
						(data) => data.week === weekNumber,
					);

					const selectedWeekDate = setWeek(
						new Date(currentYear, 0, 1),
						weekNumber,
						{
							weekStartsOn: 1,
						},
					);

					const totalValue = weekData
						? weekData[metric].reduce(
								(acc: number, userData: { value: number; user_id: string }) => {
									const userInitialGoal = userInitialGoalMetric.data.find(
										(userGoal) => userGoal.user_id === userData.user_id,
									);
									if (userInitialGoal) {
										const startDate = parseISO(
											userInitialGoal.start_date.split("T")[0],
										);
										const weeksPassed = differenceInWeeks(
											startOfWeek(selectedWeekDate, { weekStartsOn: 1 }),
											startOfWeek(startDate, { weekStartsOn: 1 }),
										);

										if (weeksPassed >= 0) {
											return acc + userData.value;
										}
									}
									return acc;
								},
								0,
							)
						: 0;

					return {
						week: `Week ${weekNumber}`,
						value: totalValue,
						objective: Math.round(weeklyObjectives[index]),
					};
				},
			);

			setData(combinedData);
		}
	}, [metric, weeklyProgressData, goalMetrics, goal, userInitialGoalMetrics]);

	return (
		<Card className="flex flex-col bg-white">
			<CardHeader>
				<CardTitle className="capitalize">{metric}</CardTitle>
				<CardDescription>
					{t("dashboard.collaborator_metrics.description")}
				</CardDescription>
			</CardHeader>
			<CardContent>
				<ResponsiveContainer width="100%" height={400}>
					<ComposedChart data={data}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="week" />
						<YAxis />
						<Tooltip />
						<Legend />
						<Bar dataKey="value" barSize={20} fill={backgroundColor} />
						<Line type="monotone" dataKey="objective" stroke={borderColor} />
					</ComposedChart>
				</ResponsiveContainer>
			</CardContent>
		</Card>
	);
};

export default MetricsChartVsGoal;
