import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";

export function TeamSelect({ teams, selectedTeamId, onSelectTeam, disabled }) {
	return (
		<Select
			value={selectedTeamId}
			onValueChange={onSelectTeam}
			disabled={disabled}
		>
			<SelectTrigger className="w-[200px]">
				<SelectValue placeholder="Select a team" />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value={null}>All Teams</SelectItem>
				{teams.map((team) => (
					<SelectItem key={team.id} value={team.id}>
						{team.name}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
}
