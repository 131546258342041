/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Chart as ChartJS, registerables } from "chart.js";
import { useAPI } from "components/lib";
import { Skeleton } from "components/ui/skeleton";
import { Chart } from "react-chartjs-2";

ChartJS.register(...registerables);

const MetricsChart = ({
	organizationId = null,
	userId = null,
	dates,
	metric,
	timeInterval,
	t,
	backgroundColor = "rgba(75, 145, 192, 0.2)",
	borderColor = "rgb(75, 116, 192)",
}) => {
	const url = organizationId
		? `/api/analytics/${organizationId}/${metric}?timeInterval=${timeInterval}&startDate=${dates.from.toISOString()}&endDate=${dates.to.toISOString()}`
		: `/api/analytics/user/${userId}/${metric}?timeInterval=${timeInterval}&startDate=${dates.from.toISOString()}&endDate=${dates.to.toISOString()}`;

	const { data: metricData, isLoading } = useAPI(url, "get", false);

	if (isLoading) {
		return <Skeleton className="w-full h-[400px]" />;
	}

	if (!metricData) {
		return null;
	}

	const labels = metricData.map((item) => {
		switch (timeInterval) {
			case "yearly": {
				return `${t("dashboard.collaborator_metrics.year")} ${item.year}`;
			}
			case "monthly": {
				return `${item.month}/${item.year}`;
			}
			case "weekly": {
				return `${t("dashboard.collaborator_metrics.week")} ${item.week}, ${item.year}`;
			}
			case "daily": {
				return `${item.day}/${item.month}/${item.year}`;
			}
			default: {
				return "";
			}
		}
	});

	if (metric === "reach") metric = "impressions";

	const data = {
		labels: labels,
		datasets: [
			{
				label: `${t(`dashboard.collaborator_metrics.${metric}`)}`,
				data: metricData.map((item) => item[metric]),
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				borderWidth: 1,
			},
		],
	};

	const options = {
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					callback: (value) => {
						if (value >= 1000000) {
							return `${(Math.ceil(value / 100000) / 10).toFixed(1)}m`;
						}
						if (value >= 1000) {
							return `${(Math.ceil(value / 100) / 10).toFixed(1)}k`;
						}
						return value;
					},
				},
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: (tooltipItem) => {
						const value = tooltipItem.raw;
						if (value >= 1000000) {
							return `${(Math.ceil(value / 100000) / 10).toFixed(1)}m`;
						}
						if (value >= 1000) {
							return `${(Math.ceil(value / 100) / 10).toFixed(1)}k`;
						}
						return value;
					},
				},
			},
		},
	};

	return <Chart data={data} options={options} type="bar" />;
};

export default MetricsChart;
